import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import { useDispatch, useSelector } from 'react-redux';
import floatingLottie from '../../lottie/floating_btn.json';
import floatingLiveLottie from '../../lottie/floating_Live_btn.json';
import floatingLiveNoDotLottie from '../../lottie/floating_Live_btn_no_reddot.json';
import { RegisterBtnContainer } from './FloatingRegisterElement';
import { GET_MAIN_STATUS_REQUEST } from '../../redux/ActionTypes';

function FloatingRegisterBtn() {
  const phase = useSelector((state) => state.meta.data.phase);
  const main = useSelector((state) => state.meta.data.main);
  const keynoteLive = useSelector((state) => state.meta.keynoteLive);
  const sessionLive = useSelector((state) => state.meta.sessionLive);

  const [scrolledY, setScrolledY] = useState(0);
  const [showNoneClass, setShowNoneClass] = useState(true);
  const [playAnimation, setPlayAnimation] = useState(false);
  const [activeClass, setActiveClass] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hasDot, setHasDot] = useState(false);

  const location = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    if (location.pathname !== '/') {
      dispatch({ type: GET_MAIN_STATUS_REQUEST });
    }
  }, [dispatch]);

  const isPage = (...pageNames) => {
    return pageNames.some((pageName) => location.pathname.includes(pageName));
  };

  const handleScroll = useCallback(() => {
    const scrollAmount = document.documentElement.scrollTop;
    setScrolledY(scrollAmount);

    if (scrollAmount >= 100) {
      setPlayAnimation(true);
      setShowNoneClass(false);
      setActiveClass(true);
    } else {
      setPlayAnimation(false);
      setShowNoneClass(true);
      setActiveClass(false);
    }
  }, []);

  // Image hover event handler
  const handleImageHover = () => {
    if (window.innerWidth >= 768) {
      setHovered(true);
    }
  };

  // remove to Image hover event handler
  const handleImageHoverLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      {isPage(
        'keynote',
        'sessions',
        'speakers',
        'schedule',
        'event',
        'support',
        'mypage',
        'terms',
        'privacy',
        'cookie',
        'codelab',
        'mega-session'
      ) ? (
        <RegisterBtnContainer>
          <Link to="/register" className="RegisterLink">
            <Lottie
              animationData={floatingLottie}
              loop={false}
              gotoandstop={91}
            />
          </Link>
        </RegisterBtnContainer>
      ) : isPage('register', 'registration') ? null : (
        <RegisterBtnContainer>
          <Link to="/register" className="RegisterLink">
            {playAnimation && (
              <Lottie
                animationData={floatingLottie}
                loop={false}
                onComplete={() => {
                  // Lottie 애니메이션이 끝났을 때 호출되는 함수
                }}
              />
            )}
          </Link>
        </RegisterBtnContainer>
      )}
    </>
  );
}

export default FloatingRegisterBtn;
