import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DetailContainer from '../../components/DetailContainer';
import DetailHero from '../../components/DetailHero';
import KeynoteCont from '../../components/KeynoteCont';
import KeynoteSpeaker from '../../components/KeynoteSpeaker';
import Loading from '../../components/Loading';
import { GET_KEYNOTE_REQUEST } from '../../redux/ActionTypes';
import withRouter from '../../components/Router/withRouter';
import {
  SESSION_ON_DEMAND,
  SESSION_OPEN,
  SESSION_ERROR,
  SESSION_CLOSED,
  SESSION_READY,
  SESSION_REPLAY,
} from '../../data/status';

const Keynote = (props) => {
  const [noPlayable, setNoPlayAble] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_KEYNOTE_REQUEST });
  }, []);

  // keynote data
  const {
    isLoading,
    keynoteData: data,
    error,
  } = useSelector((state) => state.sessionList);

  const {
    keynote: keynoteMeta,
    phase,
    keynoteChapter,
  } = useSelector((state) => state.meta.data);

  // error handling
  useEffect(() => {
    const { navigate } = props;
    if (error && navigate) {
      navigate('/internal_server_error');
    }
  }, [error]);

  return (
    <>
      {/* Cadmium data */}
      <DetailContainer>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {phase === 'phase3' &&
            keynoteMeta.videoStatus !== SESSION_READY &&
            keynoteMeta.videoStatus !== SESSION_ON_DEMAND ? null : (
              <DetailHero
                bgPc1="bg-keynote-banner-pc-1.svg"
                bgPc2="bg-keynote-banner-pc-2.svg"
                bgMo1="bg-keynote-banner-mo-1-1.png"
                bgMo2="bg-keynote-banner-mo-2.svg"
                bgColor="#1259AA"
                title="Keynote"
                path="Keynote"
              />
            )}
            {data && data.PresentationTitle && (
              <>
                <KeynoteCont
                  title={data.PresentationTitle}
                  desc={data.AbstractText.replace(
                    /(?:\\[rn]|[\r\n]+)+/g,
                    '<br/>'
                  )}
                  startDate={data.PresentationDateTimeStart}
                  sessionData={data}
                  sessionStatus={keynoteMeta.videoStatus}
                  released={
                    (keynoteMeta.videoStatus === SESSION_OPEN ||
                      keynoteMeta.videoStatus === SESSION_REPLAY) &&
                    keynoteMeta.url &&
                    phase !== 'phase2' &&
                    !noPlayable
                  }
                  uploadedBefore={
                    phase !== 'phase2' &&
                    keynoteMeta.videoStatus === SESSION_CLOSED
                  }
                  onDemand={
                    phase !== 'phase2' &&
                    keynoteMeta.videoStatus === SESSION_ON_DEMAND &&
                    keynoteMeta.url
                  }
                  url={
                    keynoteMeta.videoStatus === SESSION_OPEN ||
                    keynoteMeta.videoStatus === SESSION_REPLAY ||
                    keynoteMeta.videoStatus === SESSION_ON_DEMAND
                      ? keynoteMeta.url
                      : ''
                  }
                  vodOpen={false}
                  emergency={keynoteMeta.videoStatus === SESSION_ERROR}
                  notiTime={keynoteMeta.notiTime}
                  live={data.status === 'live'}
                  time="10:00 AM PT"
                  noPlayable={noPlayable}
                  setNoPlayable={() => setNoPlayAble(true)}
                  chapterData={keynoteChapter}
                />
                <KeynoteSpeaker isKeynote />
              </>
            )}
          </>
        )}
      </DetailContainer>
      {/* <DetailContainer>
        <>
          {phase3 === true ? null : (
            <DetailHero
              bgPc="sdc23-bg-keynote.png"
              bgMo="sdc23-bg-keynote-mo.png"
              bgColor="#1259AA"
              title="Keynote"
              path="Keynote"
            />
          )}

          <KeynoteCont
            title="SDC23 Keynote"
            desc="<p>Unveil Samsung’s latest software, services, and platforms that are poised to shape the future of customer experiences.</p>"
            session={false}
            released={false}
            vodOpen={false}
            url="https://youtu.be/1O3drPCZlTo"
          />
          <KeynoteSpeaker />
        </>
      </DetailContainer> */}
    </>
  );
};

export default withRouter(Keynote);
