import styled from 'styled-components';

export const TechSquare = styled.div`
  position: relative;
  padding-bottom: 144px;
  padding-top: 100px;
  background-color: #f6f6f6;

  .react-tabs__tab-list {
    display: flex;
    align-items: flex-end;
    max-width: 1200px;
    margin: 0 auto 48px;
    padding: 0 40px;
  }

  .react-tabs__tab {
    width: 25%;
    padding: 20px 0;
    font-family: 'samsungsharpsans-Medium';
    font-size: 18px;
    line-height: 1.44;
    color: #9e9e9e;
    border-bottom: 1px solid #ddd;
    text-align: center;
    cursor: pointer;
    &--selected {
      font-family: 'samsungsharpsans-bold';
      color: #2088fd;
      border-bottom: 2px solid #2088fd;
    }

    .hide-pc {
      display: none;
    }
  }

  @media screen and (max-width: 1160px) {
    .react-tabs__tab {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 48px;
    padding-top: 48px;

    .react-tabs__tab-list {
      padding: 0 24px;
      margin: 0 auto 32px;
      white-space: nowrap;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 0;
        line-height: 0;
      }
    }

    .react-tabs__tab {
      width: 33.333%;
      flex: 0 0 auto;
      padding: 14px 14px 13px 14px;
      font-size: 14px;
      &--selected {
        color: #2088fd;
        border-bottom: 2px solid #2088fd;
      }

      &:last-child {
        min-height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .hide-pc {
        display: block;
      }
    }
  }
`;

export const TechSquareTabCon = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const TechSquareTabConInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -12px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const CardMoreBtn = styled.div`
  margin-top: 40px;
  text-align: center;
  @media (max-width: 768px) {
  }
`;

export const EventTechSquareMap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    display: block;
    padding: 0 24px;
  }
`;

export const MapContainer = styled.div`
  position: relative;
  max-width: 1200px;
  padding: 43px 0 120px;
  overflow-x: hidden !important;

  @media screen and (max-width: 768px) {
    padding: 30px 0 60px;
  }
`;

export const EventTopUtil = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 79px 0 0;

  .link-prev {
    position: relative;
    padding-left: 32px;
    font-family: 'samsungOne-700';
    font-size: 20px;
    line-height: 1.3;
    color: #000;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-previous.svg`});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 86px auto 0;
    padding: 4px 0;

    .link-prev {
      display: flex;
      align-items: center;
      padding-left: 26px;
      font-size: 16px;
      line-height: 20px;
      &:before {
        content: '';
        top: 0;
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const MapWrap = styled.div`
  position: relative;
  display: flex;
  border-radius: 36px;
  background: #f6f6f6;
  height: auto;
  max-height: 675px;
  padding: 5px 61px 5px 61px;

  .fl-txt {
    width: 80px;
    font-family: 'samsungsharpsans-bold';
    font-size: 42px;
    line-height: 1.28;
    color: #000;
    margin-top: 40px;
    text-align: center;
  }

  @media (max-width: 768px) {
    display: block;
    padding: 25px 16px 16px 16px;
    height: auto;
    border-radius: 16px;
    max-height: none;

    .fl-txt {
      width: auto;
      font-size: 24px;
      line-height: 1.33;
      margin-top: 0;
      text-align: center;
      padding-bottom: 23px;
      margin-bottom: 16px;
      border-bottom: 1px solid #eaeaea;
    }
  }
`;

export const MapImage = styled.div`
  height: auto;
  padding: 0 59px 0 0;
  margin: 0 auto;

  .MapImageBox {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 700px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 0;

    .MapImageBox {
      max-width: none;
    }

    .reset-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.06);
      backdrop-filter: blur(9px);
      border-radius: 40px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 40px;
        height: 40px;
        background-image: url(${`${process.env.PUBLIC_URL}/images/ico-reset.svg`});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      &.is-active {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
`;
export const MapImageBox = styled.div`
  .react-transform-wrapper {
    width: 100% !important;
    height: 100% !important;
  }

  @media (max-width: 768px) {
    .map-img {
      width: 100%;
      height: 100%;
      padding-top: 40px;
      padding-bottom: 80px;
    }

    .reset-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.06);
      backdrop-filter: blur(9px);
      border-radius: 40px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 40px;
        height: 40px;
        background-image: url(${`${process.env.PUBLIC_URL}/images/ico-reset.svg`});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      &.is-active {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
`;
