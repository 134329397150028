import styled, { css } from 'styled-components';

// color vaiable
const colors = {
  green: '#10B77B',
  purple: '#756BF1',
  lightPurple: '#CA6CEB',
  lightGreen: '#96D452',
  yellow: '#FFAE34',
  sBlue: '#0F42CF',
  darkBlue: '#1259AA',
  seaBlue: '#00AFE7',
  cobaltBlue: '#2088FD',
};

export const ScheduleContainer = styled.div`
  padding: 100px 0 120px 0;

  .react-tabs__tab-list{
    display: flex;
    align-items:
    width: 100%;
    margin: 0 auto;
  }

  .react-tabs__tab{
    width: 50%;
    padding: 20px 0;
    font-family: 'samsungsharpsans-medium';
    font-size: 18px;
    line-height: 1.44;
    color: #9e9e9e;
    border-bottom: 2px solid #efefef;
    text-align: center;
    cursor: pointer;
    &--selected {
      font-family: 'samsungsharpsans-bold';
      color: #2088FD;
      border-bottom: 2px solid #2088FD;
    }
  }

  @media screen and (max-width: 768px){
    .react-tabs__tab{
      padding: 14px 0 13px;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 768px){
    padding: 40px 0 60px 0;
  }
`;

export const ScheduleInner = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const ScheduleTitle = styled.div`
  margin-bottom: 16px;
  font-family: 'samsungsharpsans-bold';
  font-size: 32px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #000;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const ScheduleWrap = styled.div`
  display: flex;
  margin-top: 60px;
  .index_text {
    font-family: 'SamsungOne-700';
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: normal;
    color: #9e9e9e;
  }
  @media screen and (max-width: 768px) {
    margin-top: 32px;
  }
`;

export const ScheduleIndex = styled.div`
  display: grid;
  width: 102px;
  grid-template-columns: 102px;
  grid-gap: 33px;
  gap: 33px;
  .index {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #efefef;
    }
    &:last-of-type:after {
      content: none;
    }
    .index_text {
      margin-top: 6px;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ScheduleGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 70px calc(100% - 136px - 180px) 198px;
  grid-gap: 24px;
  grid-row-gap: 32px;
  .index {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:before {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #efefef;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 70px;
      width: 100%;
      height: 1px;
      background-color: #efefef;
    }

    &.no-line {
      &:before {
        content: none;
      }
      &:after {
        content: none;
      }
    }

    &.last-index {
      &:before {
        content: none;
      }
      &:after {
        content: none;
      }
    }

    .index_text {
      margin-top: 6px;
    }
  }
  .box {
    position: relative;
    display: block;
    padding: 24px 24px 24px 46px;
    border-radius: 16px;
    background-color: #f6f6f6;
    box-sizing: border-box;
    transition: 0.3s;
    &:after {
      content: '';
      position: absolute;
      top: 16px;
      left: 20px;
      width: 6px;
      height: calc(100% - 32px);
      border-radius: 3px;
      background-color: #9378ff;
    }
    &.is-active {
      background-color: #eaeaea;

      .time {
        .group-line {
          background-color: transparent;
        }
      }
    }

    .title {
      position: relative;
      display: inline-block;
      padding-right: 28px;
      vertical-align: middle;
      font-family: 'SamsungOne-700';
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      letter-spacing: normal;
      color: #1e1e1e;
    }
    .time {
      position: relative;
      margin-top: 6px;
      font-family: 'SamsungOne-700';
      font-size: 16px;
      font-weight: 700;
      line-height: 1.14;
      letter-spacing: normal;

      .group-line {
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.08);
        position: absolute;
        left: -3px;
        bottom: -24px;
        transition: 0.2s;
      }
    }

    .link {
      position: relative;
      display: inline-block;
      margin-top: 20px;
      padding-right: 10px;
      font-family: 'SamsungOne-700';
      font-size: 14px;
      font-weight: bold;
      line-height: 1.57;
      letter-spacing: normal;
      color: #1259aa;
      word-break: break-all;
      .link_icon {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-left: 4px;
        vertical-align: middle;
        background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-navy.svg`});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      &:hover {
        color: #2088fd;
        .link_icon {
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-blue.svg`});
        }
      }
    }
  }

  .item_wrap {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #efefef;
    }
    .time {
      color: #7c7c7c;
    }
    &.item-01 {
      grid-column: span 2;
      .box {
        &:after {
          background-color: #65acfe;
        }
      }
    }
    &.item-02 {
      grid-column: span 2;
      .box {
        &:after {
          background-color: #1259aa;
        }

        .title {
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position: center right;
        }
      }
    }
    &.item-03 {
      grid-column: span 2;
      .box {
        position: relative;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        &:after {
          background-color: #756bf1;
        }

        .title {
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position: center right;
        }
      }

      &:before {
        content: none;
      }
    }
    &.item-04 {
      grid-row: span 2;
      &:before {
        content: none;
      }
      .box {
        height: 100%;
        &:after {
          background-color: #65acfe;
        }
      }
    }
    &.item-05 {
      &:before {
        content: none;
      }
      grid-column: span 2;
      .box {
        &:after {
          background-color: #2088fd;
        }
      }
    }
    &.item-06 {
      grid-column: span 2;
    }
    &.item-07 {
      grid-column: span 2;
      &:before {
        content: none;
      }
      .box {
        &:after {
          background-color: #1259aa;
        }

        .title {
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position: center right;
        }
      }
    }
    &.item-03-1 {
      grid-column: span 2;
      margin-top: -16px;
      &:after {
        content: '';
        position: absolute;
        bottom: -16px;
        right: -40px;
        width: 40px;
        height: 1px;
        background-color: #efefef;
      }
      .box {
        .title {
          ${(props) =>
            props.p3 &&
            css`
              background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
            `}
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position: center right;
        }

        &:after {
          background-color: #ca6ceb;
        }
      }
    }

    &.item-09 {
      .box {
        &:after {
          background-color: #1259aa;
        }

        .title {
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position: center right;
        }
      }
    }
  }

  &.on-site {
    .index {
      &.last-index {
        &:before {
          content: none;
        }
        &:after {
          content: none;
        }
      }
    }
    .item_wrap {
      &.item-lunch,
      &.item-break {
        grid-column: span 2;
        .box {
          &:after {
            content: '';
            background-color: #9e9e9e;
          }
        }
      }
      &.item-mega {
        grid-column: span 2;
        &:before {
          content: '';
        }
        .box {
          &::after {
            background-color: ${colors.purple};
          }
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          .title {
            background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
            background-size: 18px 18px;
            background-repeat: no-repeat;
            background-position: center right;
          }
        }
      }
      &.item-01 {
        .box {
          &:after {
            content: '';
            background-color: #2088fd;
          }
        }
      }
      &.item-03 {
        grid-column: span 2;

        &:before {
          content: '';
          background-color: #efefef;
        }

        .box {
          &::after {
            background-color: #756bf1;
          }
        }
      }
      &.item-04 {
        grid-row: span 4;
        &:before {
          content: '';
        }
      }
      &.item-05 {
        grid-column: span 2;

        &:before {
          content: '';
          background-color: #efefef;
        }

        .box {
          &:after {
            background-color: #7c7c7c;
          }

          .time {
            color: #7c7c7c;
          }
        }
      }
      &.item-07 {
        grid-column: span 2;
        &:before {
          content: '';
        }

        .box {
          &::after {
            background-color: #2088fd;
          }
        }
        .box {
          &:after {
            background-color: #0f42cf;
          }
        }
      }
      &.item-07-1 {
        grid-column: span 2;
        &:after {
          content: '';
          position: absolute;
          bottom: -16px;
          right: -40px;
          width: 40px;
          height: 1px;
          background-color: #efefef;
        }
        .box {
          &:after {
            background-color: #ffae34;
          }

          .title {
            ${(props) =>
              props.p3 &&
              css`
                background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
              `}
            background-size: 18px 18px;
            background-repeat: no-repeat;
            background-position: center right;
          }
        }
      }
      &.item-08 {
        grid-column: span 2;
        .box {
          &:after {
            background-color: #7c7c7c;
          }
        }
      }
      &.item-09 {
        grid-column: span 2;
        &:before {
          content: none;
        }
        .box {
          &:after {
            background-color: #96d452;
          }
        }
      }
      &.last-item {
        &:before {
          content: none;
        }
      }
    }
  }

  .index_text {
    font-family: 'SamsungOne-700';
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: normal;
    color: #9e9e9e;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 16px);
    grid-template-columns: 0 50% 50%;
    grid-gap: 12px !important;
    gap: 12px 16px !important;
    margin-left: -16px;
    .box {
      .time {
        .group-line {
          bottom: -16px;
        }
      }
    }

    .item_wrap {
      &:before {
        content: none;
      }
      &.item-mega {
        .box {
          padding: 16px 18px 16px 27px;
        }
      }
      &.item-03,
      &.item-05,
      &.item-mega {
        grid-column: span 2;
      }
      &.item-04 {
        grid-row: unset;
        grid-column: span 2;
      }
      &.item-06 {
        .box {
          min-height: auto;
        }
      }
      &.item-07 {
        .is-pc {
          display: none;
        }
      }
      &.item-07-1 {
        &:after {
          display: none;
        }
      }

      &.item-02 {
        .box {
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position: center right 18px;
          .title {
            display: block;
            background-image: none;
          }
        }
      }
      &.item-03 {
        .box {
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position: center right 18px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          .title {
            display: block;
            background-image: none;
          }
          &:before {
            content: '';
            left: 20px;
            bottom: 0;
            width: 91%;
          }
        }
        .w-100 {
          .title {
            padding-bottom: 0;
            border-bottom: none;
          }

          &.is-appended {
            padding-bottom: 0;
            border-bottom: none;
          }
        }
      }

      &.item-03-1 {
        margin-top: 0px;

        .box {
          ${(props) =>
            props.p3 &&
            css`
              background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
            `}
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position: center right 18px;
          .title {
            display: block;
            background-image: none;
          }
          &:before {
            content: '';
            left: 20px;
            bottom: 0;
            width: 91%;
          }
        }

        &:after {
          content: none;
        }
      }
      &.item-mega {
        .box {
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position: center right 18px;
          .title {
            display: block;
            background-image: none !important;
          }
        }
      }
      &.item-07 {
        .box {
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position: center right 18px;
          .title {
            display: block;
            background-image: none !important;
          }
        }
      }
      &.item-07-1 {
        .box {
          ${(props) =>
            props.p3 &&
            css`
              background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
            `}
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position: center right 18px;
          .title {
            display: block;
            background-image: none !important;
          }
        }
      }
      &.item-09 {
        .box {
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`});
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position: center right 18px;
          .title {
            display: block;
            background-image: none !important;
          }
        }
      }
    }
    .box {
      padding: 16px 18px 16px 27px;
      border-radius: 8px;
      &:after {
        left: 10px;
        width: 5px;
      }
      .title {
        font-size: 18px;
        line-height: 24px;
      }

      .time {
        font-size: 14px;
      }
    }
    .index {
      width: 0;
      height: 0;
      overflow: hidden;
    }
    &.on-site {
      .item_wrap {
        &.item-03,
        &.item-04,
        &.item-05,
        &.item-06 {
          grid-column: span 2;
          &::before,
          &:after {
            content: none;
          }
        }
        &.item-04 {
          grid-row: unset;
          &:before,
          &:after {
            content: none;
          }
        }
        &.item-07 {
          grid-column: span 2;
          &:before,
          &:after {
            content: none;
          }
        }
      }
    }
  }
`;

export const ScheduleBanner = styled.div`
  margin-top: 80px;

  @media screen and (max-width: 768px) {
    margin-top: 40px;
  }
`;

export const EngagementBox = styled.div`
  position: relative;
  height: 276px;

  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

export const EngagementBoxBg = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  @media screen and (max-width: 768px) {
    border-radius: 12px;
  }
`;

export const EngagementBoxInner = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0 40px;
  text-align: left;
  z-index: 2;

  @media screen and (max-width: 768px) {
    display: block;
    padding: 24px;
    text-align: left;
    .btn_wrap {
      button {
        width: 100%;
      }
      button + button {
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }
`;

export const EngagementTitle = styled.div`
  margin-bottom: 16px;
  font-family: 'SamsungOne-700';
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: normal;
  color: #fff;
  &.bright {
    color: #000;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 1.25;
  }
`;

export const EngagementText = styled.div`
  max-width: 760px;
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
  white-space: pre-line;
  &.bright {
    color: rgba(0, 0, 0, 0.8);
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    font-size: 14px;
    line-height: 1.43;
  }
`;

export const GridInfo = styled.div`
  padding: 32px 40px 40px;
  border-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: #f6f6f6;
  .inner {
    display: flex;
    flex-wrap: nowrap;
    max-height: 410px;
    overflow: hidden;
    &.h-auto {
      max-height: initial;
    }

    &.has-first {
      max-height: 445px;
    }
  }
  &.is-appended {
    .inner {
      max-height: none;
    }
  }
  @media screen and (max-width: 768px) {
    display: block;
    padding: 20px;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .inner {
      max-height: none;
      flex-wrap: wrap;

      &.has-first {
        max-height: none;
      }
    }
  }
`;

export const GridInfoList = styled.div`
  width: calc(50% - 8px);
  & + & {
    margin-left: 16px;
  }

  &.w-100 {
    width: 100%;
  }

  &.w-33 {
    width: calc(33.333% - 8px);
  }

  &.has-bigCard {
    .GridInfoItem:nth-of-type(3) {
      min-height: 348px;
    }
  }

  .title {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    font-family: 'SamsungOne-700';
    font-size: 24px;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
    cursor: inherit;
  }

  .grid_session_flex {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  @media screen and (max-width: 1077px) {
    &.w-33 {
      width: calc(33.333% - 4px);
    }
  }

  @media screen and (max-width: 850px) {
    .title {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    &.w-33 {
      width: 100%;

      &:last-child {
        .title {
          padding-bottom: 0;
          border-bottom: none;
        }
        &.is-appended {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
    & + & {
      margin: 20px 0 0 0;
    }
    &.is-margin {
      margin-top: 16px;
      .title {
        display: none;
      }
    }
    &.has-bigCard {
      .GridInfoItem:nth-of-type(3) {
        min-height: auto;
      }
    }

    .grid_session {
      height: 0;
      overflow: hidden;
    }
    .grid_session_flex {
      height: 0;
      overflow: hidden;

      &.h-auto {
        height: auto;
        overflow: auto;
      }
    }
    &.is-appended {
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 20px;

      .grid_session {
        height: auto;
        overflow: auto;
      }
      .grid_session_flex {
        height: auto;
        overflow: auto;
      }
      button.title {
        margin-bottom: 16px;
        padding-bottom: 0;
        border-bottom: 0;
      }
      .info_btn_icon {
        transform: rotate(180deg);
      }
    }
    .title {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 1.25;
      cursor: pointer;
    }
    button.title {
      position: relative;
      margin-bottom: 0;
      padding-bottom: 20px;
      border-bottom: 1px solid #efefef;
      .info_btn_icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 14px;
        height: 14px;
        background-image: url(${`${process.env.PUBLIC_URL}/images/sdc24-ico-arrow-black.svg`});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
  }
`;

export const GridInfoItem = styled.div`
  position: relative;
  padding: 24px 24px 52px 24px;
  border-radius: 12px;
  background-color: #fff;
  height: 166px;
  box-sizing: border-box;
  & + & {
    margin-top: 16px;
  }

  &.w-50 {
    width: calc(50% - 8px);
    height: auto;
    min-height: 166px;
    padding: 24px;
    & + & {
      margin-left: 16px;
    }
    &:nth-of-type(2) {
      margin-top: 0;
    }
    &:nth-of-type(2n-1) {
      margin-left: 0 !important;
    }

    .item_desc {
      margin-bottom: 48px;
    }

    .item_speaker {
      width: 66%;
    }

    .item_link {
      // position: relative;
      // bottom: auto;
      // left: auto;
    }
  }

  &.first {
    height: 214px;
  }
  &.h-190 {
    height: 190px;
  }

  .item_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-family: 'SamsungOne-700';
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: normal;
    color: #1e1e1e;
  }
  .item_desc {
    display: flex;
    margin-top: 6px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #7c7c7c;
  }
  .item_time {
    display: block;
  }
  .item_speaker {
    position: relative;
    width: 61%;
    padding-left: 17px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: 8px;
      width: 1px;
      height: 12px;
      background-color: #ddd;
    }
  }
  .item_link {
    position: absolute;
    bottom: 24px;
    left: 24px;
    display: inline-block;
    margin-top: 10px;
    padding-right: 10px;
    font-family: 'SamsungOne-700';
    font-size: 14px;
    font-weight: bold;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${colors.cobaltBlue};
    word-break: break-all;
    .icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-left: 4px;
      vertical-align: middle;
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-blue.svg`});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    &:hover {
      color: #2088fd;
      .icon {
        background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-blue.svg`});
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .item_title {
      -webkit-line-clamp: 2;
    }
    .item_desc {
      font-size: 12px;
      line-height: 1.45;
    }
  }

  @media screen and (max-width: 1000px) {
    .item_speaker {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  @media screen and (max-width: 850px) {
    .item_title {
      font-size: 18px;
      line-height: 22px;
    }
  }
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 20px;
    border-radius: 8px;
    & + & {
      margin-top: 16px;
    }
    &.w-50 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: auto;
      padding: 20px;
      min-height: auto;
      margin-left: 0 !important;
      & + & {
        margin-top: 16px;
      }

      .item_desc {
        margin-bottom: 8px;
      }
    }
    &.first {
      height: auto;
    }
    &.h-190 {
      height: auto;
    }
    .item_title {
      font-size: 14px;
      line-height: 20px;
      overflow: initial;
      text-overflow: unset;
      display: inline-block;
      -webkit-line-clamp: none;
      -webkit-box-orient: unset;
    }
    .item_desc {
      display: block;
      line-height: 20px;
      font-size: 14px;
    }
    .item_speaker {
      display: block;
      padding-left: 0;

      &:before {
        display: none;
      }
    }
    .item_link {
      position: relative;
      bottom: 0;
      left: 0;
      // margin-top: 16px;
    }
  }
`;

export const GridInfoMore = styled.button`
  position: relative;
  display: block;
  margin: 24px auto 0 auto;
  padding-right: 20px;
  font-family: 'SamsungOne-700';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #000;
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    right: 0;
    width: 16px;
    height: 16px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/sdc24-ico-arrow-black.svg`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
  }
  &.is-appended:after {
    transform: rotate(180deg);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
