import React, { useState, useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  TechContainer,
  TechInner,
  TechContent,
  TechTitle,
  TechCardArea,
  TechCardBox,
  TechCardBoxDimmed,
  TechCardBoxBlur,
  TechCardBoxBg,
  TechCardBoxInner,
  TechBoxInfo,
  TechBoxTitle,
  TechBoxDate,
  TechBoxText,
  Button,
} from './EventContElements';
import arrLottie from '../../lottie/plus-close-btn.json';
import ToastPopup from '../ToastPopup';
import ScheduleMap from '../ScheduleMap';
import PopupTechSquare from '../PopupTechSquare';
import EventMap from '../EventMap';

const techSquareCardData = [
  {
    id: 1,
    title: 'Meet-up',
    date: 'October 3',
    desc: 'Talk with product experts, experience innovations in Tech Square. Catch up on new updates from Samsung platforms and OS like SmartThings, Knox and Tizen, Mobile & Screen Experience, Home & Health Experience, Sustainability.',
    bg: `${process.env.PUBLIC_URL}/images/img-sdc24-event-01-1.png`,
    url: '/event/tech-square',
    singleLine: false,
    hideBtn: false,
    // theme : 'bright'
  },
  {
    id: 2,
    title: 'Bingo',
    date: 'October 3',
    desc: "Get ready for the SDC24 Bingo Event! Attendees will receive event-related bingo missions to complete throughout the day. As you create bingo lines, you'll earn a lucky draw ticket for the nighttime party and collect points to redeem for fun swags.",
    bg: `${process.env.PUBLIC_URL}/images/img-sdc24-event-02-1.png`,
    url: '/event/samsung-clab',
    singleLine: false,
    hideBtn: false,
    // theme : 'bright'
  },
  {
    id: 3,
    title: '"Code&Chill" Night Party',
    date: 'October 3',
    desc: 'After a day of learning, networking and coding, join us in celebrating the 10th anniversary of SDC. The SDC Night Party will feature exciting music, unlimited food and drinks, and a lucky draw for everyone to enjoy.',
    bg: `${process.env.PUBLIC_URL}/images/img-sdc24-event-03.png`,
    url: '/event/samsung-clab',
    singleLine: false,
    hideBtn: false,
    theme: 'large',
  },
  // {
  //   id: 3,
  //   title: 'Hacker’s Playground',
  //   date: 'October 5, 11:30 AM',
  //   desc: 'Discover another side of writing secure programs. Hacker’s playground is a casual hacking competition that anyone can try, offering an opportunity to practice while also providing self-study materials geared toward each level.',
  //   bg: `${process.env.PUBLIC_URL}/images/sdc23-TechSquareCard-3.png`,
  //   url: '',
  //   singleLine: false,
  //   hideBtn: true,
  //   // theme : 'bright'
  // },
  // {
  //   id: 4,
  //   title: 'Bits & Bytes',
  //   date: 'October 5, 6:00 PM',
  //   desc: 'After the final mega session, indulge in the official SDC23 after-party. "Bits and Bytes" marks the event’s culmination, featuring an exciting live performance, enjoyable arcade games, and a lucky draw.',
  //   // desc: 'Discover another side of writing secure programs. Hacker’s playground is a casual hacking competition that anyone can try, offering an opportunity to practice while also providing self-study materials geared toward each level.',
  //   bg: `${process.env.PUBLIC_URL}/images/sdc23-TechSquareCard-4.png`,
  //   url: '',
  //   singleLine: false,
  //   hideBtn: true,
  //   // theme : 'bright'
  // },
];

// Card component
const TechCardItem = ({
  item,
  idx,
  setModal,
  setModalFalse,
  hideBtn,
  singleLine,
  url,
}) => {
  const phase = useSelector((state) => state.meta.data.phase);

  // Tech Square popup
  const [openTechPopup, setOpenTechPopup] = useState(false);
  const [openClabPopup, setOpenClabPopup] = useState(false);

  // Arrow lottie button
  const [isHovered, setIsHovered] = useState(false);
  const [shouldUseLottie, setShouldUseLottie] = useState(true);

  // Handle window resize event
  useEffect(() => {
    const handleResize = () => {
      setShouldUseLottie(true);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [isClicked, setIsClicked] = useState(false);
  const handleClick = () => {
    if (!animationRef.current) return;

    if (isClicked) {
      animationRef.current.goToAndStop(0, true);
    } else {
      animationRef.current.play();
    }

    setIsClicked(!isClicked);
  };

  const popupHandler = () => {
    if (item.id === 1) {
      setOpenTechPopup(true);
    } else if (item.id === 2) {
      setOpenClabPopup(true);
    }
  };

  const animationRef = useRef(null); // Lottie 인스턴스를 참조할 ref

  // 애니메이션을 초기화하는 함수
  const resetAnimation = () => {
    if (animationRef.current) {
      animationRef.current.goToAndStop(0, true);
    }
  };

  return (
    <>
      {phase === 'phase3' || phase === 'phase4' ? (
        <>
          {/* phase3에서 상세 페이지로 이동 */}
          <Link to={url}>
            <TechCardBox
              key={idx}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={item.theme === 'large' ? 'col-1' : null}
            >
              <div className="TechSquareBoxLink">
                <TechCardBoxBlur className="Blur" />
                <TechCardBoxBg>
                  <img className="TechSquareImg" src={item.bg} alt="" />
                </TechCardBoxBg>

                <TechCardBoxInner>
                  <TechBoxInfo>
                    <TechBoxTitle className={item.theme}>
                      {item.title.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          {index !== item.title.split('\n').length - 1 && (
                            <br />
                          )}
                        </React.Fragment>
                      ))}
                    </TechBoxTitle>
                    <TechBoxDate>{item.date}</TechBoxDate>
                  </TechBoxInfo>

                  <TechBoxText
                    className={`TechBoxText ${singleLine ? 'singleLine' : ''}`}
                  >
                    <p>{item.desc}</p>
                  </TechBoxText>
                </TechCardBoxInner>

                {/* <Button
                  className={`moreLink ${hideBtn ? 'hideBtn' : ''} event`}
                >
                  {shouldUseLottie && (
                    <Lottie
                      key={isHovered ? 'not-hovered' : 'not-hovered'}
                      animationData={arrLottie}
                      loop={false}
                      autoplay={isHovered}
                      onComplete={() => {
                        // Lottie 애니메이션이 끝났을 때 호출되는 함수
                      }}
                    />
                  )}
                  {!shouldUseLottie && (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
                      alt="ic"
                    />
                  )}
                </Button> */}
              </div>
            </TechCardBox>
          </Link>
        </>
      ) : (
        <>
          {/* phase2.5에서 팝업 제공 */}
          <TechCardBox
            key={idx}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            className={`${item.theme === 'large' ? 'col-1' : null}`}
          >
            <div
              className={`${isClicked ? 'clicked' : null} TechSquareBoxLink`}
            >
              <TechCardBoxDimmed />
              <TechCardBoxBlur className="Blur" />
              <TechCardBoxBg>
                <img className="TechSquareImg" src={item.bg} alt="" />
              </TechCardBoxBg>

              <TechCardBoxInner>
                <TechBoxInfo>
                  <TechBoxTitle className={item.theme}>
                    {item.title.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        {index !== item.title.split('\n').length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </TechBoxTitle>
                  <TechBoxDate>{item.date}</TechBoxDate>
                </TechBoxInfo>

                <TechBoxText
                  className={`TechBoxText ${singleLine ? 'singleLine' : ''}`}
                >
                  <p>{item.desc}</p>
                </TechBoxText>
              </TechCardBoxInner>

              <Button className={`moreLink ${hideBtn ? 'hideBtn' : ''}`}>
                {shouldUseLottie && (
                  <Lottie
                    lottieRef={animationRef}
                    key={isClicked || isHovered ? 'hovered' : 'not-hovered'}
                    animationData={arrLottie}
                    loop={false}
                    autoplay={isHovered || isClicked}
                    onComplete={() => {
                      // Lottie 애니메이션이 끝났을 때 호출되는 함수
                    }}
                  />
                )}
                {/* {!shouldUseLottie && (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
                    alt="ic"
                  />
                )} */}
              </Button>
            </div>
          </TechCardBox>

          {/* Tech Exhibition popoup */}
          {openTechPopup ? (
            <PopupTechSquare isLinkCont={false} setOpen={setOpenTechPopup} />
          ) : null}

          {/* Samsung C-Lab popoup */}
          {openClabPopup ? (
            <PopupTechSquare isLinkCont setOpen={setOpenClabPopup} />
          ) : null}
        </>
      )}
    </>
  );
};

const EventCont = () => {
  const phase = useSelector((state) => state.meta.data.phase);
  const [modal, setModal] = useState(false);
  const [modalFalse, setModalFalse] = useState(false);

  return (
    <TechContainer>
      <TechInner>
        {phase === 'phase3' || phase === 'phase4' ? (
          <>
            <TechContent>
              <TechTitle>Main Event in SDC24</TechTitle>
              <TechCardArea>
                {techSquareCardData.map((item, idx, id) => (
                  <TechCardItem
                    key={idx}
                    item={item}
                    idx={idx}
                    setModal={setModal}
                    hideBtn={item.hideBtn}
                    singleLine={item.singleLine}
                    setModalFalse={setModalFalse}
                    url={item.url}
                  />
                ))}
              </TechCardArea>
            </TechContent>
            <EventMap />
          </>
        ) : (
          <>
            <TechContent>
              <TechTitle>Main Event in SDC24</TechTitle>
              <TechCardArea>
                {techSquareCardData.map((item, idx, id) => (
                  <TechCardItem
                    key={idx}
                    item={item}
                    idx={idx}
                    setModal={setModal}
                    hideBtn={item.hideBtn}
                    singleLine={item.singleLine}
                    setModalFalse={setModalFalse}
                    url={item.url}
                  />
                ))}
              </TechCardArea>
            </TechContent>
          </>
        )}
      </TechInner>
      {/* <ScheduleMap /> */}
    </TechContainer>
  );
};

export default EventCont;
