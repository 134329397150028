import styled from 'styled-components';

export const TechSquareCard = styled.div`
  position: relative;
  // opacity: ${(props) => (props.listYn ? '0' : '1')};
  opacity: 1;
  position: relative;
  width: calc(50% - 24px);
  margin: 12px;
  background-color: #fff;
  border: ${(props) => (props.border ? '1px solid #dcdcdc' : 'none')};
  border-radius: 24px;
  box-sizing: border-box;
  transition: 0.3s ease-in-out;
  transform: ${(props) => (props.listYn ? 'translateY(50px)' : 'none')};
  overflow: hidden;
  &.is-active {
    transform: translateY(0);
    opacity: 1;
  }

  // TA - added style
  &.none {
    display: none;
  }

  @media screen and (min-width: 769px) {
    &:hover:not(.loading) {
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.06);

      .card_img.has-dim:before {
        background-color: rgba(0, 0, 0, 0.2);
      }

      .card_img {
        .first-video-el:before {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 16px 0 0 0;
    border-radius: 16px;
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export const TechSquareCardImg = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  max-height: 330px;
  border-radius: 24px 24px 0 0;
  // border-bottom: 1px solid #EFEFEF;
  box-sizing: border-box;
  cursor: pointer;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    transition: 0.3s;
    object-fit: cover;
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 56.1233%;
  }

  .image-thumbnail,
  .video-thumbnail {
    position: relative;

    &:after {
      content: '';
      display: block;
      padding-bottom: 56.1233%;
    }
  }

  .first-video-el {
    position: relative;
    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 2;
      transition: 0.3s;
    }
  }

  &.has-dim {
    padding: 0;
    + .Text {
      .Title {
        color: #000;
        font-family: 'SamsungOne-700';
      }
    }
    &:after {
      padding-bottom: 56.25%;
    }

    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 14px 14px 0 0;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 2;
      transition: 0.3s;
    }
  }

  @media screen and (max-width: 768px) {
    border-radius: 16px 16px 0 0;
  }
`;

export const TechSquareCardText = styled.div`
  position: relative;
  padding: 36px 40px 40px;
  background-color: #fff;
  border-radius: 24px;
  cursor: pointer;

  .title {
    position: relative;
    overflow: hidden;
    text-overflow: 2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: 'SamsungOne-700';
    font-size: 24px;
    line-height: 32px;
  }

  .desc {
    position: relative;
    overflow: hidden;
    text-overflow: 3;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
    color: #363636;
    opacity: 0.8;
  }

  .subtitle {
    margin: 6px 0 20px;
    font-size: 16px;
    line-height: 24px;
    color: #363636;
    opacity: 0.8;
  }

  .link {
    padding-right: 18px;
    font-family: 'SamsungOne-700';
    font-size: 14px;
    line-height: 20px;
    color: #2088fd;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-blue.svg`});
    background-repeat: no-repeat;
    background-position: right center;
    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 24px;
    border-radius: 16px;

    .title {
      min-height: 48px;
      font-size: 18px;
      line-height: 24px;
    }

    .desc {
      text-overflow: 4;
      -webkit-line-clamp: 4;
      margin-top: 12px;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const VideoIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
  border-radius: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.47);
  z-index: 2;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/sdc24-ico-play.svg`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
    &:before {
      width: 10px;
      height: 10px;
    }
  }
`;

export const LoadingCard = styled.div`
  border-radius: 24px;
  background-color: #fff;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
    border-radius: 16px;
  }
`;

export const LoadingCardInner = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const LoadingCardTop = styled.div`
  position: relative;
  background-color: #eaeaea;
  border-radius: 24px 24px 0 0;
  &:after {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }
  @media screen and (max-width: 768px) {
    border-radius: 16px 16px 0 0;
    &:after {
      padding-bottom: 56.25%;
    }
  }
`;

export const LoadingCardTopInner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  transition: 0.3s;
  object-fit: cover;
  border-radius: 14px 14px 0 0;
  @media screen and (max-width: 768px) {
  }
`;

export const LoadingCardIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    animation-name: blink;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    & + .dot {
      margin-left: 10px;
    }
    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }
  @keyframes blink {
    0% {
      opacity: 0.08;
    }
    25% {
      opacity: 0.14;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.08;
    }
  }

  @media screen and (max-width: 768px) {
    .dot {
      width: 6px;
      height: 6px;
      & + .dot {
        margin-left: 8px;
      }
    }
  }
`;

export const LoadingCardBottom = styled.div`
  width: 100%;
  padding: 36px 40px 32px;
  background-color: #fff;
  border-radius: 0 0 14px 14px;
  box-sizing: border-box;
  div {
    width: 100%;
    height: 20px;
    border-radius: 4px;
    background-color: #f6f6f6;
    & + div {
      margin-top: 24px;
    }
    &:first-of-type {
      width: 100%;
      margin-top: 0;
      height: 32px;
    }
    &:nth-of-type(2) {
      width: 65.555%;
      margin-top: 4px;
      height: 32px;
    }
    &:nth-of-type(4) {
      margin-top: 4px;
    }
    &:last-of-type {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 24px;
    border-radius: 0 0 16px 16px;
    div {
      width: 100%;
      height: 16px;
      border-radius: 4px;
      background-color: #f6f6f6;
      & + div {
        margin-top: 13px;
      }
      &:first-of-type {
        width: 100%;
        margin-top: 0;
        height: 24px;
      }
      &:nth-of-type(2) {
        width: 71.211%;
        margin-top: 4px;
        height: 24px;
      }
      &:nth-of-type(4) {
        margin-top: 4px;
      }
      &:last-of-type {
        display: block;
        margin-top: 4px;
      }
    }
  }
`;

export const EventTechSquarePopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 96px 24px;
  }
`;

export const EventTechSquarePopupInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .scroll {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 920px;
    max-height: 782px;
    padding: 56px 0;
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-close.png`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  @media screen and (max-width: 768px) {
    .scroll {
      padding: 40px 0 24px;
      border-radius: 16px;
    }

    .close-btn {
      top: 14px;
      right: 14px;
      width: 20px;
      height: 20px;
    }
  }
`;

export const EventTechSquarePopupCon = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 56px;
  box-sizing: border-box;
  overflow-y: auto;

  &.has-link-popup {
    max-height: 834px;
  }

  .single-img {
    display: block;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
    border-radius: 16px;
  }
`;

export const EventTechSquarePopupSlide = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  max-height: 454px;
  border-radius: 24px;
  box-sizing: border-box;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    transition: 0.3s;
    object-fit: cover;
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 49.3495%;
  }

  .video-thumbnail,
  .image-thumbnail {
    position: relative;

    &:after {
      content: '';
      display: block;
      padding-bottom: 49.3495%;
    }
  }

  .video-thumbnail {
    iframe {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      transition: 0.3s;
      object-fit: cover;
    }
  }
`;

export const EventTechSquarePopupInfo = styled.div`
  padding-top: 76px;

  &.single-desc {
    padding-top: 24px;
  }

  .title {
    font-family: 'SamsungOne-700';
    font-size: 24px;
    line-height: 32px;
  }

  .desc {
    // max-height: 120px;
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
    color: #535353;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
    }
    a {
      color: #2088fd;
    }
  }

  .subtitle {
    margin: 8px 0 12px;
    font-size: 18px;
    line-height: 24px;
    color: #363636;
    opacity: 0.8;
  }

  .link {
    display: inline-block;
    margin-bottom: 12px;
    padding-right: 18px;
    font-family: 'SamsungOne-700';
    font-size: 14px;
    line-height: 20px;
    color: #2088fd;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-blue.svg`});
    background-repeat: no-repeat;
    background-position: right center;
    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 36px;

    .title {
      margin: 6px 0 10px;
      font-size: 18px;
      line-height: 24px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 24px;
    }

    .desc {
      margin-top: 12px;
      font-size: 14px;
      line-height: 20px;
    }

    .link {
      margin-bottom: 8px;
    }
  }
`;

export const LinkBox = styled.a``;
