import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  FilterContainer,
  FilterDim,
  FilterInner,
  FilterTop,
  FilterTitle,
  FilterClose,
  FilterClearWrap,
  FilterClear,
  FilterCont,
  FilterItemBox,
  FilterItemWrap,
  FilterItem,
  FilterItemTitle,
  FilterItemCont,
  FilterCount,
  FilterBox,
} from './FilterElements';
import ButtonFilter from '../ButtonFilter';

import {
  CLICK_FILTER_CLEAR,
  GET_SESSION_LIST_REQUEST,
} from '../../redux/ActionTypes';

const SessionMainFilter = {
  'Device AI': 'Device AI',
  SmartThings: 'SmartThings',
  'Responsible AI': 'Responsible AI',
  'Advanced Tech': 'Advanced Tech',
  'Enterprise & Ecosystem': 'Enterprise & Ecosystem',
};

const SessionTopicFilter = {
  AI: 'AI',
  Android: 'Android',
  // AR: 'AR',
  // Connectivity: 'Connectivity',
  // 'Contents & Service': 'Contents&Service',
  // Data: 'Data',
  Design: 'Design',
  Enterprise: 'Enterprise',
  ESG: 'ESG',
  Game: 'Game',
  Health: 'Health',
  'IoT/Connectivity': 'IoT/Connectivity',
  Mobile: 'Mobile',
  Monetization: 'Monetization',
  // 'Open Innovation': 'Open Innovation',
  'Open Sources': 'Open Sources',
  Platform: 'Platform',
  Productivity: 'Productivity',
  'Security & Privacy': 'Security & Privacy',
  'Service & Content': 'Service & Content',
  'Smart Appliances': 'Smart Appliances',
  'Smart TV': 'Smart TV',
  Tizen: 'Tizen',
  'UI/UX': 'UI/UX',
  // UWB: 'UWB',
  Wearable: 'Wearable',
  // Web: 'Web',
};

const Filter = ({ setOpen }) => {
  const [topicsOpen, setTopicsOpen] = useState(false);
  const [mainCateOpen, setMainCateOpen] = useState(false);
  const [isTransition, setIsTransition] = useState(false);

  const closeFilter = () => {
    setOpen(false);
    document.body.style.overflow = 'auto';
  };

  const toggleTopics = () => {
    setIsTransition(true);
    setTopicsOpen(!topicsOpen);
  };

  const toggleMainCategories = () => {
    setIsTransition(true);
    if (topicsOpen) setTopicsOpen(false);
    setMainCateOpen(!mainCateOpen);
  };

  const checkedTypes = useSelector((state) => state.sessionFilter.checkedTypes);
  const checkedTopics = useSelector(
    (state) => state.sessionFilter.checkedTopics
  );
  const checkedMainCategories = useSelector(
    (state) => state.sessionFilter.checkedMainCategories
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const clearHandler = () => {
    setSearchParams();
    dispatch({ type: CLICK_FILTER_CLEAR });
    dispatch({ type: GET_SESSION_LIST_REQUEST, query: [] });
  };

  const handleTransitionEnd = () => {
    if (mainCateOpen || topicsOpen) return;
    setTimeout(() => setIsTransition(false), 0);
  };

  const handleClick = () => {
    if (mainCateOpen) setMainCateOpen(false);
    if (topicsOpen) setTopicsOpen(false);
  };

  useEffect(() => {
    // componentWillUnmount
    return () => {
      closeFilter();
    };
  }, []);

  return (
    <FilterContainer>
      <FilterInner>
        <FilterCont>
          {(mainCateOpen || topicsOpen) && <FilterBox onClick={handleClick} />}
          <FilterItemBox className="main-categories">
            <FilterItemWrap>
              <FilterItem
                className={`main-categories ${mainCateOpen ? 'open' : null} ${
                  isTransition ? 'transition' : null
                }`}
                onTransitionEnd={handleTransitionEnd}
              >
                <FilterItemTitle onClick={toggleMainCategories}>
                  Main Categories
                  <span className="count">
                    <em className={checkedMainCategories.length ? '' : 'empty'}>
                      {checkedMainCategories.length}
                    </em>
                    {`/${Object.keys(SessionMainFilter).length}`}
                  </span>
                  <button
                    className={`more-btn ${mainCateOpen ? 'close' : ''}`}
                    type="button"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/sdc24-ico-arrow-black.svg`}
                      alt="arr"
                    />
                  </button>
                </FilterItemTitle>
                <FilterItemCont
                  className={`main-categories ${mainCateOpen ? '' : 'close'}`}
                >
                  {Object.entries(SessionMainFilter).map(([key, value]) => (
                    <ButtonFilter
                      key={key}
                      item={key}
                      title={SessionMainFilter[value]}
                      type="single"
                    />
                  ))}
                </FilterItemCont>
                <div className="empty-block" />
              </FilterItem>
            </FilterItemWrap>
          </FilterItemBox>
          <FilterItemBox>
            <FilterItemWrap>
              <FilterItem
                className={`${isTransition ? 'transition' : null} ${
                  topicsOpen ? 'open' : null
                }`}
                onTransitionEnd={handleTransitionEnd}
              >
                <FilterItemTitle onClick={toggleTopics}>
                  Topics
                  <span className="count">
                    <em className={checkedTopics.length ? '' : 'empty'}>
                      {checkedTopics.length}
                    </em>
                    {`/${Object.keys(SessionTopicFilter).length}`}
                  </span>
                  <button
                    className={`more-btn ${topicsOpen ? 'close' : ''}`}
                    type="button"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/sdc24-ico-arrow-black.svg`}
                      alt="arr"
                    />
                  </button>
                </FilterItemTitle>
                <FilterItemCont
                  className={`topic-filter ${topicsOpen ? '' : 'close'}`}
                >
                  {Object.keys(SessionTopicFilter).map((item) => (
                    <ButtonFilter
                      key={item}
                      item={item}
                      title={SessionTopicFilter[item]}
                    />
                  ))}
                </FilterItemCont>
                <div className="empty-block" />
              </FilterItem>
            </FilterItemWrap>
          </FilterItemBox>
        </FilterCont>
        <FilterClearWrap>
          <FilterClear
            className={
              checkedTypes.length +
                checkedTopics.length +
                checkedMainCategories.length ===
              0
                ? 'disabled'
                : null
            }
            onClick={
              checkedTypes.length +
                checkedTopics.length +
                checkedMainCategories.length ===
              0
                ? null
                : clearHandler
            }
          >
            Clear All
          </FilterClear>
          <FilterCount>
            {checkedTypes.length +
              checkedTopics.length +
              checkedMainCategories.length}
          </FilterCount>
        </FilterClearWrap>
      </FilterInner>
    </FilterContainer>
  );
};

export default Filter;
