// My Page - User Data
export const UserData = {
  FirstName: 'First Name',
  LastName: 'Last Name',
  Email: 'abc.efg@company.com',
  ProfileImage: `${process.env.PUBLIC_URL}/images/MyProfile_img.png`,
  isOnline: false,
};

// My Page - Register Data
export const RegisterData = [
  {
    firstName: 'First Name',
    lastName: 'Last Name',
    personalUrl: 'https://www.linkedin.com/in',
    type: 'offline',
    country: 'Korea, Republic of',
    company: 'Samsung Electronics',
    occupation: 'Designer',
    jobLevel: 'Coporate Executive/C-level/SVP',
    phoneNumber: '010-0000-0000',
    topic: 'Contents & Service, Developer Program, Open Innovation',
    partnerType: 'partner',
    registrationType: 'Attendee',
    isValid: false,
  },
  {
    firstName: 'First Name',
    lastName: 'Last Name',
    personalUrl: 'https://www.linkedin.com/in',
    type: 'online',
    country: 'Korea, Republic of',
    company: 'Samsung Electronics',
    occupation: 'Designer',
    jobLevel: 'Corporate Executive/C-level/SVP',
    phoneNumber: '010-0000-0000',
    topic: 'Contents & Service, Developer Program, Open Innovation',
    partnerType: 'partner',
    isValid: true,
  },
];
