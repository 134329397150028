import styled, { keyframes } from 'styled-components';

export const TechContainer = styled.div`
  position: relative;
  padding: 60px 0 120px 0;
  background-color: #f6f6f6;
  @media screen and (max-width: 1024px) {
    padding: 89px 0 60px 0;
  }
`;

export const TechInner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    display: block;
    padding: 0 24px;
  }
`;

export const TechFilterBtn = styled.button`
  display: none;
  /* position: fixed;
	top: 56px; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 14px 24px;
  font-family: 'SamsungOne-700';
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000;
  text-align: left;
  background-color: #fff;
  background-image: url(${`${process.env.PUBLIC_URL}/images/ico-filter.svg`});
  background-repeat: no-repeat;
  background-position: right 24px center;
  background-size: 16px 16px;

  span {
    display: none;
  }

  &.is-checked {
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-filter-fill.svg`});
    background-repeat: no-repeat;
    background-position: right 24px center;
    background-size: 16px 16px;

    span {
      display: inline;
      padding-left: 4px;
      color: #7c7c7c;
    }
  }
  @media screen and (max-width: 1024px) {
    display: block;
    border: solid 1px #efefef;
  }
`;

export const TechTop = styled.div`
  max-width: 1200px;
  margin-bottom: 40px;
  @media screen and (max-width: 1024px) {
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 49;
    margin-bottom: 0;
  }
`;

export const TechBottom = styled.div`
  max-width: 1200px;
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0;
  }
`;

export const CardWrap = styled.div`
  .empty {
    width: 100%;
    margin: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 402px;
    font-family: 'SamsungOne-700';
    font-size: 16px;
    color: #535353;
    border-radius: 24px;
    box-sizing: border-box;
    background-color: #fff;
  }

  @media screen and (max-width: 768px) {
    .empty {
      min-height: 173px;
      margin: 0;
      font-size: 14px;
      border-radius: 16px;
    }
  }
`;

export const CardMoreBtn = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  @media screen and (max-width: 768px) {
  }
`;

export const CardInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin-right: -24px;
  @media screen and (max-width: 768px) {
    margin-right: 0;
    gap: 0;
  }
`;

export const CardRoomWrap = styled.div`
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const CardRoomInner = styled.div`
  .card_item:first-of-type {
    .card_type {
      margin-left: 0;
    }
    .card_img {
      padding: 0;
    }
    .card_btn {
      left: 0;
    }
  }
  .card_bg {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 24px;
    background-color: #fff;
    padding: 40px;
  }
  @media screen and (max-width: 768px) {
    .card_bg {
      border-radius: none;
      background-color: transparent;
      padding: 0;
    }
    .card_item:first-of-type {
      .card_img {
        padding: 24px;
      }
    }
  }
`;

export const LiveRoom = styled.div`
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;

  @media screen and (max-width: 768px) {
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
`;

export const LiveRoomInner = styled.div`
  position: relative;
  padding: 28px 40px 32px;
  background-color: #fff;
  border-radius: 24px;

  .title {
    margin-bottom: 4px;
    font-family: 'SamsungOne-700';
    font-size: 24px;
    line-height: 32px;
    color #000;
  }

  .time {
    margin-bottom: 20px;
    font-family: 'SamsungOne-700';
    font-size: 16px;
    line-height: 24px;
    color: #7c7c7c;
  }

  .noti {
    display: flex;
    align-items: center;
    color: #9e9e9e;

    br {
      display: none;
    }

    svg {
      margin-right: 6px;
    }
  }

  .ic {
    position: absolute;
    top: 50%;
    right: 40px;
    height: 32px;
    transform: translateY(-50%);
  }

  @media screen and (max-width: 768px) {
    padding: 14px 20px 20px 14px;
    border-radius: 16px;

    .title {
      font-size: 18px;
      line-height: 24px;
    }

    .time {
      font-size: 14px;
      line-height: 20px;
    }

    .noti {
      align-items: flex-start;
      font-size: 12px;
      line-height: 16px;
      br {
        display: block;
      }
    }

    .ic {
      width: 20px;
      height: 20px;
      right: 20px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

const circleFade = keyframes`
  0% {
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

export const LiveRoomTag = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 12px;
  padding: 8px 16px;
  font-family: 'SamsungOne-700';
  line-height: 1.43;
  color: #fff;
  border-radius: 18px;
  background-color: #adadad;
  &.live-on {
    padding: 8px 12px 8px 26px;
    background-color: #fa5347;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 14px;
      left: 12px;
      width: 8px;
      height: 8px;
      margin-right: 6px;
      background-color: #fff;
      border-radius: 100%;
      animation: ${circleFade} 0.8s infinite linear alternate;
      margin-bottom: 0.6px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 6px 12px;
    &.live-on {
      padding: 6px 12px 6px 24px;
      &::before {
        width: 6px;
        height: 6px;
      }
    }
  }
`;
