import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import QRCode from 'react-qr-code';
import {
  MyProfileWrap,
  MyProfileWrapInner,
  MyProfileDetail,
  RegisterTitle,
  RegisterContent,
  ProfileSlider,
  MyProfileDetailWrap,
  MyProfileQRCode,
  MyProfilePersonalUrlTh,
  MyProfilePersonalUrlTd,
} from './MyInfoElement';
import './style.scss';
import ButtonLink from '../ButtonLink';
import Popup from '../Popup';
import Button from '../Button';
import {
  ONLINE_UNREGISTER_REQUEST,
  ONLINE_UNREGISTER_RESET,
} from '../../redux/ActionTypes';

const desc =
  'Are you sure you want to unregister?\nIf unregistered, the reserved session also deleted.';

function SlideNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none' }}
      onClick={onClick}
    />
  );
}

function SlidePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none' }}
      onClick={onClick}
    />
  );
}

function URLValidCheck(url) {
  const urlPattern =
    /^(https:\/\/)?(www\.)?(instagram\.com|linkedin\.com|facebook\.com|github\.com|id\.linkedin\.com|uk\.linkedin\.com)(\/.*)?$/i;

  return urlPattern.test(url);
}

const MyInfo = (props) => {
  const dispatch = useDispatch();

  const { registerState } = useSelector((state) => state.myPageData);
  const { isRegistered, formData, isOnline } = props;
  const phase = useSelector((state) => state.meta.data.phase);

  // const [isOnline, setIsOnline] = useState(false);
  const [userPartner, setUserPartner] = useState(false); // userType: partner&developer일 경우 true, staff일 경우 false
  const [staffForm, setFormData] = useState({});
  const navigate = useNavigate();
  const [personalURLData, setPersonalURLData] = useState(null);

  useLayoutEffect(() => {
    if (!formData) {
      return;
    }
    if (formData && formData.length > 0) {
      const isStaff = formData.filter(
        (x) => x.partnerType.toLowerCase() === 'staff'
      );
      if (isStaff.length === 0) {
        // partner or sdp form
        setUserPartner(true);
        // get personal url data
        const getPersonalURLs = formData.filter((x) => x.personalURL);

        if (getPersonalURLs && getPersonalURLs.length > 0) {
          setPersonalURLData(
            formData
              .filter((x) => x.personalURL && URLValidCheck(x.personalURL))
              .map((x) => {
                return {
                  url: x.personalURL,
                  name: `${x.firstName} ${x.lastName}`,
                };
              })
          );
        }
      } else {
        // staff form
        setFormData(isStaff[0]);
        setUserPartner(false);
      }
    }
  }, [formData]);

  const [modal, setModal] = useState(false);
  const openPopup = () => {
    setModal(true);
  };

  const settings = {
    dots: true,
    speed: 800,
    nextArrow: <SlideNextArrow />,
    prevArrow: <SlidePrevArrow />,
    centerMode: true,
    infinite: false,
    centerPadding: '0px',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    cssEase: 'ease-in-out',
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          dots: true,
          infinite: false,
        },
      },
    ],
  };

  useEffect(() => {
    if (registerState === 'unregister') {
      setModal(false);
      dispatch({ type: ONLINE_UNREGISTER_RESET });
      navigate('/registration/canceled');
    }
  }, [registerState]);

  return (
    <MyProfileWrap>
      <MyProfileWrapInner>
        {isRegistered && formData ? (
          <>
            {userPartner ? (
              <>
                <MyProfileDetailWrap>
                  {/* slide content exposure when registration info duplicate registration */}
                  <ProfileSlider className="profile_slide">
                    <Slider {...settings}>
                      {formData.map((x) => {
                        return (
                          <MyProfileDetail
                            isRegistered
                            className={`profile_slider virtual ${
                              phase !== 'phase2' ? 'is-phase3' : ''
                            }`}
                          >
                            {phase === 'phase2' && (
                              <Button
                                className="btn-unregister"
                                color="border"
                                size="small"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (x.type === 'offline') {
                                    window.open('/registration/cancel'); // open to new tab
                                  } else {
                                    openPopup();
                                  }
                                }}
                              >
                                Unregister
                              </Button>
                            )}
                            <Button
                              className="btn-edit"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `/registration/edit?type=${x.type}`
                                ); // open to new tab
                              }}
                              color="border"
                              size="small"
                            >
                              Edit
                            </Button>
                            <table>
                              <colgroup className="col">
                                <col width="30%" />
                                <col width="70%" />
                              </colgroup>
                              <thead>
                                <tr>
                                  <th>Registration Information</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Name</th>
                                  <td>{`${x.firstName} ${x.lastName}`}</td>
                                </tr>
                                <tr>
                                  <th>
                                    <MyProfilePersonalUrlTh>
                                      Personal Url
                                      {x.personalURL &&
                                        !URLValidCheck(x.personalURL) && (
                                          <img
                                            src={`${process.env.PUBLIC_URL}/images/ico-url-danger.svg`}
                                            alt="error"
                                          />
                                        )}
                                    </MyProfilePersonalUrlTh>
                                  </th>
                                  <td>
                                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    <MyProfilePersonalUrlTd
                                      className={
                                        x.personalURL &&
                                        !URLValidCheck(x.personalURL)
                                          ? 'error'
                                          : null
                                      }
                                    >
                                      {x.personalURL || ''}
                                      {x.personalURL &&
                                        !URLValidCheck(x.personalURL) && (
                                          <span className="error-text">
                                            This URL is not permitted.
                                          </span>
                                        )}
                                    </MyProfilePersonalUrlTd>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Attendance</th>
                                  <td>
                                    {x.type === 'offline' ? (
                                      <>
                                        <div className="atten-tag">
                                          <span>In-person</span>
                                        </div>
                                        {/* {phase === 'phase4' ? (
                                        <div className="confirm-badge">
                                          <span>
                                            <svg
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10 0L12.4721 2.39155L15.8779 1.90983L16.4721 5.29772L19.5106 6.90983L18 10L19.5106 13.0902L16.4721 14.7023L15.8779 18.0902L12.4721 17.6085L10 20L7.52786 17.6085L4.12215 18.0902L3.52786 14.7023L0.489435 13.0902L2 10L0.489435 6.90983L3.52786 5.29772L4.12215 1.90983L7.52786 2.39155L10 0Z"
                                                fill="#2088FD"
                                              />
                                              <path
                                                d="M6.42969 9.44111L9.27606 12.1451L13.5725 7.85938"
                                                stroke="white"
                                                strokeWidth="1.4"
                                              />
                                            </svg>
                                            Attendance Confirmed
                                          </span>
                                        </div>
                                      ) : null} */}
                                      </>
                                    ) : (
                                      <div className="atten-tag virtual">
                                        <span>Virtual</span>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Registration Type</th>
                                  <td>
                                    {x.partnerType && x.registrationType
                                      ? [
                                          x.partnerType === 'sdp'
                                            ? 'SDP'
                                            : x.partnerType === 'sdp-student'
                                            ? 'Student'
                                            : x.partnerType
                                                .charAt(0)
                                                .toUpperCase() +
                                              x.partnerType.slice(1),
                                          x.registrationType,
                                        ].join(', ')
                                      : x.partnerType === 'sdp'
                                      ? 'SDP'
                                      : x.partnerType === 'sdp-student'
                                      ? 'Student'
                                      : x.partnerType.charAt(0).toUpperCase() +
                                        x.partnerType.slice(1)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Profile Information</th>
                                  <td>
                                    <span>{x.country}</span>
                                    <span>{x.company}</span>
                                    <span>{x.occupation}</span>
                                    <span>{x.jobLevel}</span>
                                    <span>{x.phoneNumber}</span>
                                  </td>
                                </tr>
                                {x.partnerType !== 'staff' && (
                                  <tr>
                                    <th>Topics of Interest</th>
                                    <td>{x.topic}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </MyProfileDetail>
                        );
                      })}
                    </Slider>
                    {modal ? (
                      <Popup
                        modal={modal}
                        setModal={setModal}
                        version="alert"
                        title="Unregister"
                        desc={desc}
                        CloseText="Cancel"
                        btnText="Unregister"
                        onClick={(e) => {
                          dispatch({ type: ONLINE_UNREGISTER_REQUEST });
                        }}
                      />
                    ) : null}
                  </ProfileSlider>
                </MyProfileDetailWrap>
                {personalURLData && personalURLData.length > 0 && (
                  <MyProfileDetailWrap>
                    {/* slide content exposure when registration info duplicate registration */}
                    <ProfileSlider className="profile_slide">
                      <Slider {...settings}>
                        {personalURLData.map((x) => {
                          return (
                            <MyProfileDetail
                              isRegistered
                              className={`profile_slider virtual e-business ${
                                phase !== 'phase2' ? 'is-phase3' : ''
                              }`}
                            >
                              <MyProfileQRCode>
                                <div className="title">e-Business card</div>
                                <div className="qr-code">
                                  <div className="qr-image">
                                    <QRCode
                                      style={{
                                        height: 'auto',
                                        maxWidth: '100%',
                                        width: '100%',
                                      }}
                                      value={x.url}
                                    />
                                  </div>
                                  <div className="name">{x.name}</div>
                                </div>
                              </MyProfileQRCode>
                            </MyProfileDetail>
                          );
                        })}
                      </Slider>
                    </ProfileSlider>
                  </MyProfileDetailWrap>
                )}
              </>
            ) : (
              /* staff form */
              <MyProfileDetailWrap>
                <MyProfileDetail isRegistered>
                  {phase === 'phase2' && (
                    <Button
                      className="btn-unregister"
                      url="/"
                      color="border"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open('/registration/cancel'); // open to new tab
                      }}
                    >
                      Unregister
                    </Button>
                  )}
                  <Button
                    className="btn-edit"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open('/registration/edit?type=offline'); // open to new tab
                    }}
                    color="border"
                    size="small"
                  >
                    Edit
                  </Button>
                  <table>
                    <colgroup className="col">
                      <col width="30%" />
                      <col width="70%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Registered Information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>{`${staffForm.firstName} ${staffForm.lastName}`}</td>
                      </tr>
                      <tr>
                        <th>Attendance</th>
                        <td>
                          <div className="atten-tag">
                            <span>In-person</span>
                          </div>
                          {/* {phase === 'phase4' ? (
                          <div className="confirm-badge">
                            <span>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10 0L12.4721 2.39155L15.8779 1.90983L16.4721 5.29772L19.5106 6.90983L18 10L19.5106 13.0902L16.4721 14.7023L15.8779 18.0902L12.4721 17.6085L10 20L7.52786 17.6085L4.12215 18.0902L3.52786 14.7023L0.489435 13.0902L2 10L0.489435 6.90983L3.52786 5.29772L4.12215 1.90983L7.52786 2.39155L10 0Z"
                                  fill="#2088FD"
                                />
                                <path
                                  d="M6.42969 9.44111L9.27606 12.1451L13.5725 7.85938"
                                  stroke="white"
                                  strokeWidth="1.4"
                                />
                              </svg>
                              Attendance Confirmed
                            </span>
                          </div>
                        ) : null} */}
                        </td>
                      </tr>
                      <tr>
                        <th>Registration Type</th>
                        <td>
                          {staffForm.partnerType
                            ? staffForm.partnerType === 'sdp'
                              ? 'SDP'
                              : staffForm.partnerType.charAt(0).toUpperCase() +
                                staffForm.partnerType.slice(1)
                            : ''}
                          <br /> {staffForm.registrationType}
                        </td>
                      </tr>
                      <tr>
                        <th>Profile Information</th>
                        <td>
                          <span>{staffForm.country}</span>
                          <span>{staffForm.company}</span>
                          {/* <span>{formData.occupation}</span> */}
                          <span>{staffForm.jobLevel}</span>
                          {/* check countryCode */}
                          <span>{staffForm.phoneNumber}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {modal ? (
                    <Popup
                      modal={modal}
                      setModal={setModal}
                      version="alert"
                      title="Unregister"
                      desc={desc}
                      CloseText="Cancel"
                      btnText="Unregister"
                    />
                  ) : null}
                </MyProfileDetail>
              </MyProfileDetailWrap>
            )}
          </>
        ) : (
          phase === 'phase2' && (
            // Remove MyProfileDetail exposure after registration period
            <MyProfileDetailWrap>
              <MyProfileDetail className="unregister" isRegistered={false}>
                <RegisterTitle>Registered Information</RegisterTitle>
                <RegisterContent>
                  <p>
                    You are not yet registered. <br /> Register and get the
                    benefits offered.
                  </p>
                  <ButtonLink
                    className="register-btn"
                    url="/register"
                    color="black"
                    size="medium"
                  >
                    Register Now
                  </ButtonLink>
                </RegisterContent>
              </MyProfileDetail>
            </MyProfileDetailWrap>
          )
        )}
      </MyProfileWrapInner>
    </MyProfileWrap>
  );
};

export default MyInfo;
