import { call, put, takeLatest, select } from 'redux-saga/effects';
import moment from 'moment-timezone';
import { Get } from '../../utils/Requester';
import * as types from '../ActionTypes';
import { getBookmarkList } from './UserBookmarkSaga';
import { getReservationList } from './ReservationSaga';
import { getMetaLive } from './MetaSaga';
import { diffTime } from '../../utils/Functions';
import { sessionData } from '../../sample/SessionData';
import { UserData, RegisterData } from '../../sample/UserData';

function* getMyPage() {
  try {
    yield call(getMetaLive);

    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield put({ type: types.GET_BOOKMARK_LIST, data: sessionData });
      // NOTE: bookmark data none
      // yield put({ type: types.GET_BOOKMARK_LIST, data: [] });

      yield put({ type: types.GET_RESERVATION_LIST, data: sessionData });
      // NOTE: reservation data none
      // yield put({ type: types.GET_RESERVATION_LIST, data: [] });
      yield put({
        type: types.GET_MY_PAGE_SUCCESS,
        data: { ...UserData, formData: RegisterData },
        likeData: sessionData,
      });

      // NOTE: Non-registered user
      // yield put({
      //   type: types.GET_MY_PAGE_SUCCESS,
      //   data: { ...UserData, formData: [] },
      //   likeData: sessionData,
      // });
      return;
    }

    const { data, error } = yield call(Get, { url: '/api/user/my-page' });
    let bookmark = yield select((state) => state.bookmarkList.bookmark);

    if (!bookmark) {
      bookmark = yield call(getBookmarkList);
    }

    const phase = yield select((state) => state.meta.data.phase);
    const gapTime = yield select((state) => state.meta.gapTime);

    const d =
      gapTime === 0 ? moment.utc().valueOf() : moment.utc().valueOf() - gapTime;
    const currentTime = moment
      .tz(d, 'America/Los_Angeles')
      .format('YYYY-MM-DD[T]HH:mm:ss');

    if (phase === 'phase3') {
      bookmark.forEach((x) => {
        if (x.SessionType.toLowerCase() === 'mega') {
          // x.status = mega?.badgeStatus;
          const { status, diff } = diffTime(
            currentTime,
            x.PresentationDateTimeStart,
            x.PresentationDateTimeEnd
          );
          x.status = status;
        }
      });
      yield put({ type: types.GET_BOOKMARK_LIST, data: bookmark });
    }
    if (error || typeof data === 'string') {
      console.error('MyPageSaga', error);
      if (error === 'Unauthorized') {
        window.location.href = '/login?redirectURL=/mypage';
        return;
      }
      yield put({
        type: types.GET_MY_PAGE_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
      return;
    }

    // get reservation list
    // if (data.userData?.formData.length > 0) {
    //   try {
    //     let reservationList = yield call(getReservationList);
    //     const registerType =
    //       data.userData?.formData.filter((x) => x.type === 'offline').length >
    //       0;
    //     if (reservationList.length > 0 && !registerType) {
    //       // online user > filter data
    //       reservationList = reservationList.filter(
    //         (x) => x.SessionType.toLowerCase() === 'mega'
    //       );
    //     }
    //     reservationList = reservationList
    //       .map((x) => {
    //         if (x.SessionType.toLowerCase() === 'mega') {
    //           x.order = 1;
    //         } else if (x.SessionType.toLowerCase() === 'tech') {
    //           x.order = 2;
    //         } else {
    //           x.order = 3;
    //         }
    //         return x;
    //       })
    //       // sort rule: start time > session type > end time > session name
    //       .sort(
    //         (a, b) =>
    //           new Date(a.PresentationDateTimeStart) -
    //             new Date(b.PresentationDateTimeStart) ||
    //           a.order - b.order ||
    //           new Date(a.PresentationDateTimeEnd) -
    //             new Date(b.PresentationDateTimeEnd) ||
    //           a.PresentationTitle.localeCompare(b.PresentationTitle)
    //       );
    //     yield put({ type: types.GET_RESERVATION_LIST, data: reservationList });
    //   } catch (err) {
    //     console.error('get reservation list error', err);
    //   }
    // }

    // const likeSessions = yield call(Get, { url: '/api/sdc/like' });
    // const { likeList } = likeSessions.data;
    // if (phase === 'phase3') {
    //   likeList.forEach((x) => {
    //     if (x.SessionType.toLowerCase() === 'mega') {
    //       // x.status = mega.badgeStatus;
    //       const { status, diff } = diffTime(
    //         currentTime,
    //         x.PresentationDateTimeStart,
    //         x.PresentationDateTimeEnd
    //       );
    //       x.status = status;
    //     }
    //   });
    // }

    yield put({
      type: types.GET_MY_PAGE_SUCCESS,
      data: data.userData,
      likeData: [],
    });
  } catch (error) {
    if (error) {
      console.error('MyPageSaga', error);
      yield put({ type: types.GET_MY_PAGE_ERROR, error });
    }
  }
}

function* unRegisterOnLine() {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield put({ type: types.ONLINE_UNREGISTER_SUCCESS });
      return;
    }
    const { data, error } = yield call(Get, { url: '/api/user/unregister' });

    if (error || typeof data === 'string') {
      console.error('Unregister', error);
      yield put({
        type: types.ONLINE_UNREGISTER_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
      return;
    }

    yield put({ type: types.ONLINE_UNREGISTER_SUCCESS });
  } catch (error) {
    if (error) {
      console.error('MyPageSaga', error);
      yield put({ type: types.ONLINE_UNREGISTER_ERROR, error });
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_MY_PAGE_REQUEST, getMyPage);
  yield takeLatest(types.ONLINE_UNREGISTER_REQUEST, unRegisterOnLine);
}
