import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import moment from 'moment-timezone';
import * as types from '../ActionTypes';
import { Get } from '../../utils/Requester';
import { diffTime, markEventVersion } from '../../utils/Functions';
import { getLikedSession, getLikeCount } from './UserLikeSaga';
import { getBookmarkCount } from './UserBookmarkSaga';
import { getReservation } from './ReservationSaga';
import { getMetaLive } from './MetaSaga';
import { sessionDetail } from '../../sample/SessionDetail';
import { megaSessionDetail } from '../../sample/MegaSessionData';

function* getSessionDetail({ sessionId }) {
  try {
    // yield call(getModeData);
    yield call(getMetaLive);
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield delay(3000);

      yield put({
        type: types.GET_RESERVATION_ITEM,
        registerType: 'offline',
        isReservation: false,
      });

      // NOTE: get common session data
      yield put({
        type: types.GET_SESSION_DETAIL_SUCCESS,
        data: sessionDetail,
      });

      // NOTE: get mega session data
      // yield put({
      //   type: types.GET_SESSION_DETAIL_SUCCESS,
      //   data: megaSessionDetail,
      // });

      yield put({
        type: types.UPDATE_BOOKMARK_COUNT,
        bookmarkCount: 100,
      });

      yield put({
        type: types.UPDATE_LIKE_COUNT,
        likeCount: 100,
      });
      return;
    }

    const { data, headers, error } = yield call(Get, {
      url: `/api/event/session/${sessionId}`,
    });

    if (error || typeof data === 'string') {
      console.error('SessionDetailSaga', error || data);
      yield put({
        type: types.GET_SESSION_DETAIL_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
      return;
    }

    if (headers['mark-event-version']) {
      markEventVersion(headers['mark-event-version']);
    }

    let { session } = data;

    const gapTime = yield select((state) => state.meta.gapTime);
    const d =
      gapTime === 0 ? moment.utc().valueOf() : moment.utc().valueOf() - gapTime;
    const currentTime = moment
      .tz(d, 'America/Los_Angeles')
      .format('YYYY-MM-DD[T]HH:mm:ss');

    const { phase, 'on-demand': onDemand } = yield select(
      (state) => state.meta.data
    );

    if (phase === 'phase3' && session.SessionType.toLowerCase() === 'mega') {
      // session.status = mega?.badgeStatus;
      const { status, diff } = diffTime(
        currentTime,
        session.PresentationDateTimeStart,
        session.PresentationDateTimeEnd
      );
      session.status = status;
    } else if (phase === 'phase3') {
      // NEED TO CHECK
      session.status = onDemand?.videoStatus;
    }

    if (session?.relatedSession?.length > 0) {
      const { relatedSession } = session;
      if (phase === 'phase3') {
        relatedSession.forEach((x) => {
          if (x.SessionType.toLowerCase() === 'mega') {
            // x.status = mega?.badgeStatus;
            const { status, diff } = diffTime(
              currentTime,
              x.PresentationDateTimeStart,
              x.PresentationDateTimeEnd
            );
            x.status = status;
          }
        });
      }
    }

    const relatedVideo = [];
    session.relatedVideo.forEach((x) => {
      if (x.url) {
        const url = new URL(x.url);
        const args = new URLSearchParams(url.search);
        relatedVideo.push({
          id:
            x.url.indexOf('www.youtube.com') > -1
              ? new URLSearchParams(args).get('v')
              : x.url.split('/').pop().replace(url?.search, ''),
          title: x.title,
          description: x.description,
          url: x.url,
        });
      }
    });
    session.relatedVideo = [...relatedVideo];

    const noDetailCont =
      session.codelab.length === 0 &&
      session.relatedVideo.length === 0 &&
      session.relatedSession.length === 0 &&
      session.resources.length === 0 &&
      !(session.technical && session.technical.url.length > 0);

    session = {
      ...session,
      Presenters: session.Presenters.filter(
        (x) => x.PresentationRosterRole !== 'Moderator'
      ),
      category: session.category ? session.category : null,
      mainCategory: session.mainCategory
        ? // ? session.mainCategory.replace('Others', 'Enterprise & Ecosystem')
          session.mainCategory
        : null,
      noDetailCont,
    };

    // get reservation data
    const { isLogin } = yield select((state) => state.userSession);
    if (isLogin) {
      const { type, isReservation } = yield call(getReservation, {
        sessionId: session.PresentationID,
      });

      yield put({
        type: types.GET_RESERVATION_ITEM,
        registerType: type,
        isReservation,
      });
    }

    yield put({
      type: types.GET_SESSION_DETAIL_SUCCESS,
      data: session,
    });

    const isLiked = yield call(getLikedSession, data.session.PresentationID);
    yield put({
      type: types.GET_LIKED_SESSION,
      isLiked: isLiked === true ? 1 : 0 === 1,
    });

    const bookmarkCount = yield call(getBookmarkCount, {
      sessionId: session.PresentationID,
    });
    yield put({
      type: types.UPDATE_BOOKMARK_COUNT,
      bookmarkCount,
    });

    const likeCount = yield call(getLikeCount, {
      sessionId: session.PresentationID,
    });
    yield put({
      type: types.UPDATE_LIKE_COUNT,
      likeCount,
    });
  } catch (error) {
    if (error) {
      console.error('SessionDetailSaga', 'error', error);
      yield put({ type: types.GET_SESSION_DETAIL_ERROR, error });
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_SESSION_DETAIL_REQUEST, getSessionDetail);
}
