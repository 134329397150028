import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import Slider from 'react-slick';
import ReactPlayer from 'react-player/lazy';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import {
  KeynoteContContainer,
  KeynoteContInner,
  KeynoteContVideo,
  KeynoteContVideoBox,
  KeynoteContVideoInner,
  KeynoteContSoon,
  KeynoteContText,
  KeynoteContTitle,
  KeynoteContDesc,
  KeynoteBadge,
  KeynoteBadgeWrap,
} from '../KeynoteCont/KeynoteContElements';
import {
  SessionRoomNoti,
  SessionRoomSchedule,
  RoomScheduleGrid,
  SessionRoomScheduleInner,
  RoomTitle,
  RoomScheduleBox,
  RoomScheduleInner,
  RoomScheduleTime,
  RoomScheduleTitle,
  RoomScheduleWrap,
} from './SessionRoomContElement';
import { convertURL } from '../../utils/Functions';
import Popup from '../Popup';
import { SESSION_READY, SESSION_OPEN, SESSION_CLOSED } from '../../data/status';

const SessionRoomCont = (props) => {
  const {
    released,
    url,
    session,
    title,
    desc,
    vodOpen,
    isError,
    phase4,
    notiTime,
    gapTime,
    live,
    sessionStatus,
    uploadedBefore,
    emergency,
    noPlayable,
    setNoPlayable,
  } = props;

  // phase4 유무 체크
  // const [phase4, setPhase4] = useState(false);
  const [notiModal, setNotiModal] = useState(false);
  const notiModalTimeOut = useRef(null);

  const ref = useRef(null);
  const playerRef = useRef(null);

  // noti popup
  useLayoutEffect(() => {
    if (!notiTime || !sessionStatus || sessionStatus !== SESSION_READY) {
      return;
    }
    const d =
      gapTime === 0 ? moment.utc().valueOf() : moment.utc().valueOf() - gapTime;
    const currentTime = moment
      .tz(d, 'America/Los_Angeles')
      .format('YYYY-MM-DD[T]HH:mm:ss');
    if (
      moment(currentTime).isBefore(notiTime) &&
      sessionStatus === SESSION_READY
    ) {
      const diff = moment(notiTime).diff(currentTime);
      notiModalTimeOut.current = setTimeout(() => {
        setNotiModal(true);
      }, diff);
    }
    return () => {
      clearTimeout(notiModalTimeOut.current);
    };
  }, [notiTime, sessionStatus]);

  const keynoteStageData = [
    {
      title: 'Gamepad on Tizen TV',
      time: '11:30 - 12:00',
      speaker: 'Rongchun Wu',
    },
    {
      title: 'HDR10+ Gaming',
      time: '12:10 - 12:40',
      speaker: 'Seongnam Oh, Suzie Hyun, Gennady Petrov',
    },
    {
      title: 'Games with Samsung Galaxy',
      time: '13:30 - 15:20',
      speaker:
        'Michael Barnes, David Berger, Dongmin Chwa, Jong Hyuk Woo, Sangchul Song',
    },
    {
      title:
        'Exploring the Digital Health Ecosystem: Samsung Health as Digital Front Door',
      time: '16:10 - 18:00',
      speaker: 'Hon Pak, Alex Gao, James Geraci, Sungchull Lee, Eric Song',
    },
  ];

  return (
    <>
      <KeynoteContContainer
        className={released ? 'KeynoteCont is-released' : 'KeynoteCont'}
      >
        <KeynoteContInner className="KeynoteContInner">
          {released ? (
            <>
              <KeynoteContVideoBox />
              <KeynoteContVideo
                className="KeynoteContVideo is-released"
                released={released}
              >
                <KeynoteContVideoInner>
                  <ReactPlayer
                    ref={playerRef}
                    className="react-player"
                    playing={sessionStatus === SESSION_OPEN}
                    url={url}
                    width="100%"
                    height="100%"
                    controls
                    muted={sessionStatus === SESSION_OPEN}
                    onError={(e) => {
                      setNoPlayable(true);
                      console.warn('mega session youtube error', e);
                    }}
                  />
                </KeynoteContVideoInner>
              </KeynoteContVideo>
            </>
          ) : uploadedBefore ? (
            <>
              <KeynoteContVideoBox />
              <KeynoteContVideo
                className="KeynoteContVideo is-released"
                released={released}
              >
                <KeynoteContVideoInner>
                  <div className="KeynoteBg">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/keynote_uploadBefore_bg.png`}
                      alt="session is live"
                    />
                  </div>
                  <KeynoteContSoon>
                    <div className="title">
                      Mega Session replay will be <br />
                      uploaded soon.
                    </div>
                  </KeynoteContSoon>
                </KeynoteContVideoInner>
              </KeynoteContVideo>
            </>
          ) : emergency ? (
            <>
              <KeynoteContVideoBox />
              <KeynoteContVideo
                className="KeynoteContVideo is-released"
                released={released}
              >
                <KeynoteContVideoInner>
                  <div className="KeynoteBg">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Emergency.png`}
                      alt="session is live"
                    />
                  </div>
                  <KeynoteContSoon>
                    <div className="title">
                      Stay tuned!
                      <br /> We’ll be back in just a moment!
                    </div>
                  </KeynoteContSoon>
                </KeynoteContVideoInner>
              </KeynoteContVideo>
            </>
          ) : noPlayable ? (
            <>
              <KeynoteContVideoBox />
              <KeynoteContVideo
                className="KeynoteContVideo is-released"
                released={released}
              >
                <KeynoteContVideoInner>
                  <div className="KeynoteBg">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Emergency.png`}
                      alt="session is live"
                    />
                  </div>
                  <KeynoteContSoon>
                    <div className="title">
                      Unable to play the video. <br />
                      Please refresh this page or <br />
                      visit our official channel <br />
                      on YouTube/@SamsungDevelopers
                    </div>
                  </KeynoteContSoon>
                </KeynoteContVideoInner>
              </KeynoteContVideo>
            </>
          ) : (
            <KeynoteContVideo className="KeynoteContVideo">
              <img
                className="react-player"
                src={`${process.env.PUBLIC_URL}/images/video-keynote-comingsoon.gif`}
                alt="coming-soon"
              />
              <KeynoteContSoon className="LiveVideo has-dim">
                <div className="title">
                  The live session will open
                  <br /> at 11:30 AM PT.
                </div>
              </KeynoteContSoon>
            </KeynoteContVideo>
          )}

          <KeynoteContTitle
            className={
              session
                ? 'KeynoteContTitle is-session'
                : released && !session
                ? 'KeynoteContTitle is-released'
                : 'KeynoteContTitle'
            }
          >
            {/* keynote Live badge */}
            {!session && released && !vodOpen && live ? ( // 라이브 오픈 후, vod 업로드 이전
              <KeynoteBadgeWrap>
                <KeynoteBadge>
                  <div className="inner">
                    <span className="tag">LIVE</span>
                  </div>
                </KeynoteBadge>
              </KeynoteBadgeWrap>
            ) : (
              ''
            )}
            <div className="title">{title}</div>
          </KeynoteContTitle>

          <KeynoteContText
            className={
              session ? 'KeynoteContText is-session' : 'KeynoteContText'
            }
          >
            <KeynoteContDesc
              className="KeynoteContDesc"
              dangerouslySetInnerHTML={{ __html: desc }}
            />
            <SessionRoomNoti>
              Welcome to our Mega Session! Join live streaming of the exciting
              sessions here. Please note that actual running times may be
              subject to change and possibly differ slightly from what has been
              previously announced.
            </SessionRoomNoti>
          </KeynoteContText>
        </KeynoteContInner>
      </KeynoteContContainer>

      {/* SessionRoomSchedule */}
      <SessionRoomSchedule>
        <SessionRoomScheduleInner>
          <RoomTitle>Schedule</RoomTitle>
          {/* RoomScheduleWra */}
          <RoomScheduleWrap>
            <RoomScheduleInner>
              <div className="top">
                <RoomScheduleTitle>11:30 - 18:00</RoomScheduleTitle>
              </div>
              <RoomScheduleGrid>
                {keynoteStageData.map((item, idx) => (
                  <RoomScheduleBox>
                    <Link to={`/sessions/${convertURL(item.title)}`}>
                      <div className="title">{item.title}</div>
                      <div className="info">
                        <div className="time">{item.time}</div>
                        <span className="speaker">{item.speaker}</span>
                      </div>
                      <span className="more">View More</span>
                    </Link>
                  </RoomScheduleBox>
                ))}
              </RoomScheduleGrid>
            </RoomScheduleInner>
          </RoomScheduleWrap>
        </SessionRoomScheduleInner>
      </SessionRoomSchedule>
      {notiModal ? (
        <Popup
          modal={notiModal}
          setModal={setNotiModal}
          notify="Refresh the page to view the live session."
          btnText="Confirm"
          reload
        />
      ) : null}
    </>
  );
};

export default SessionRoomCont;
