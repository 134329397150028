import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import 'odometer/themes/odometer-theme-default.css';
import { useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import {
  HeroArrow,
  HeroContainer,
  HeroContent,
  HeroDim,
  HeroInner,
  HeroIntroBtn,
  HeroIntroBtnWrap,
  HeroIntroDesc,
  HeroIntroDescItem,
  HeroIntroText,
  HeroIntroTitle,
  HeroVisual,
} from './MainHeroElements';
import Button from '../Button';
import ButtonLink from '../ButtonLink';
import withRouter from '../Router/withRouter';
import ALink from '../ALink';
import titleLottie from '../../lottie/main-hero-title.json';
import titleMoLottie from '../../lottie/main-hero-title.s.json';

const MainHero = (props) => {
  const phase = useSelector((state) => state.meta.data.phase);

  const mainRef = useRef();
  const q = gsap.utils.selector(mainRef);
  const tl = useRef();
  const visualRef = useRef();
  const contentRef = useRef();
  const titleRef = useRef();
  const descRef = useRef();
  const buttonRef = useRef();
  const arrowRef = useRef();

  // console.log('q', q);
  // console.log('mainRef', mainRef);
  // console.log('visualRef', visualRef);
  // console.log('contentRef', contentRef);
  // console.log('titleRef', titleRef);
  // console.log('descRef', descRef);
  // console.log('buttonRef', buttonRef);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 769);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 769);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const scrollPos = window.scrollY;
      if (scrollPos < 10) {
        window.scrollTo({
          top: isDesktop ? 130 : 100,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 3000);

    window.addEventListener('resize', updateMedia);
    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', updateMedia);
    };
  });

  gsap.registerPlugin(ScrollTrigger);
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    setHasRendered(true);
  }, []);
  useEffect(() => {
    if (hasRendered) {
      ScrollTrigger.matchMedia({
        '(min-width: 769px)': function () {
          tl.current = gsap
            .timeline({
              scrollTrigger: {
                trigger: mainRef.current,
                scrub: 0.5,
                start: 'top',
                end: '+=200%',
                onUpdate: (self) => {
                  if (self.progress > 0.3) {
                    buttonRef.current?.classList.add('active');
                  } else {
                    buttonRef.current?.classList.remove('active');
                  }
                },
                pin: true,
                anticipatePin: 1,
              },
            })
            .fromTo(q('.title-motion'), { y: 0 }, { y: -500, duration: 0.5 })
            .fromTo(
              visualRef.current,
              { scale: 0.78, y: 460, borderRadius: '24px' },
              { scale: 1, y: 0, borderRadius: 0 },
              '-=0.5'
            )
            .fromTo(contentRef.current, { y: 450 }, { y: 0 }, '-=0.5')
            .fromTo(
              titleRef.current,
              { scale: 0.85, y: 10 },
              { scale: 1, y: 0 },
              '-=0.5'
            )
            .fromTo(
              descRef.current,
              { y: 50, opacity: 0 },
              { y: 0, opacity: 1, duration: 1 },
              '-=0'
            )
            .fromTo(
              buttonRef.current,
              { y: 50, opacity: 0 },
              { y: 0, opacity: 1, duration: 1 },
              '-=1'
            );
        },
        '(max-width: 768px)': function () {
          tl.current = gsap
            .timeline({
              scrollTrigger: {
                trigger: mainRef.current,
                scrub: 0.5,
                start: 'top',
                end: '+=200%',
                onUpdate: (self) => {
                  if (self.progress > 0.2) {
                    buttonRef.current?.classList.add('active');
                  } else {
                    buttonRef.current?.classList.remove('active');
                  }
                },
                pin: true,
                anticipatePin: 1,
              },
            })
            .fromTo(q('.title-motion'), { y: 0 }, { y: -250, duration: 0.5 })
            .fromTo(
              visualRef.current,
              { scale: 0.86, y: 250, borderRadius: '24px' },
              { scale: 1, y: 0, borderRadius: 0 },
              '-=0.5'
            )
            .fromTo(contentRef.current, { y: 300 }, { y: 0 }, '-=0.5')
            .fromTo(
              descRef.current,
              { y: 50, opacity: 0 },
              { y: 0, opacity: 1, duration: 1 },
              '-=0'
            )
            .fromTo(
              buttonRef.current,
              { y: 50, opacity: 0 },
              { y: 0, opacity: 1 },
              '-=1'
            );
        },
      });
    }

    return () => {
      if (tl.current) tl.current.kill();
    };
  }, [q]);
  return (
    <>
      <HeroContainer>
        <HeroInner
          ref={mainRef}
          className={
            phase === 'phase3' || phase === 'phase4' ? 'is-phase3' : ''
          }
        >
          {/* <HeroDim className="hero_dim" /> */}
          <HeroVisual>
            <Lottie
              animationData={isDesktop ? titleLottie : titleMoLottie}
              loop={false}
              className="title-motion"
            />
            <div className="visual-img" ref={visualRef}>
              <ReactPlayer
                id="video"
                url={
                  isDesktop
                    ? `${process.env.PUBLIC_URL}/images/main-hero-bg-2.mp4`
                    : `${process.env.PUBLIC_URL}/images/main-hero-bg.s.mp4`
                }
                playing
                playsinline
                muted
                loop
                width=""
                height=""
              />
            </div>
          </HeroVisual>
          <HeroContent ref={contentRef}>
            <HeroIntroText className="hero_intro_text">
              <HeroIntroTitle className="hero_intro_title" ref={titleRef}>
                <span>
                  Samsung Developer <br />
                  Conference 2024
                </span>
              </HeroIntroTitle>
              <HeroIntroDesc className="hero_intro_desc" ref={descRef}>
                {/* It is necessary to check whether text branching is necessary for each phase. */}
                <HeroIntroDescItem>
                  San Jose McEnery Convention Center
                </HeroIntroDescItem>
                <HeroIntroDescItem>
                  Thur, Oct 03, 2024 10:00 AM PT
                </HeroIntroDescItem>
              </HeroIntroDesc>
              <HeroIntroBtnWrap ref={buttonRef}>
                <HeroIntroBtn className="hero_intro_btn">
                  <ALink
                    color="whiteOpacity"
                    size="medium"
                    href="https://youtu.be/MHq3rGquQhs"
                  >
                    Watch Video
                  </ALink>
                  <ButtonLink
                    url="/register"
                    color="white"
                    size="medium"
                    className="btn-reg"
                  >
                    <s className="arrow" />
                    <span>Register Now</span>
                  </ButtonLink>
                </HeroIntroBtn>
              </HeroIntroBtnWrap>
            </HeroIntroText>

            <HeroArrow ref={arrowRef}>
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-hero-arrow.svg`}
                alt="scroll down"
              />
            </HeroArrow>
          </HeroContent>
        </HeroInner>
      </HeroContainer>
    </>
  );
};

export default withRouter(MainHero);
