// Speaker Detail page
export const speakerDetail = [
  {
    PresenterID: '01',
    PresenterFirstName: 'JH',
    PresenterLastName: 'Han',
    PresenterPosition: 'President & CEO, IT & Mobile Communications Division',
    PresenterOrganization: 'Samsung Electronics',
    PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_1_23.png`,
    hoverTitle: '',
    subject: 'Keynote',
    blBg: false,
    PresenterBiographyText:
      'DJ Koh is President and CEO of Samsung Electronics’ IT and Mobile Communications Business Division, where he oversees the company’s mobile and network equipment businesses. Mr. Koh has been in charge of many successful launches of Samsung’s flagship smartphones, including the Galaxy Note8, S8/S8+ and S7/S7 edge. Prior to overseeing the mobile business, he was Head of Mobile R&D, where he played a critical role in the launch of a variety of mobile devices and services. DJ Koh is President and CEO of Samsung Electronics’ IT and Mobile Communications Business Division, where he oversees the company’s mobile and network equipment businesses. Mr. Koh has been in charge of many successful launches of Samsung’s flagship smartphones, including the Galaxy Note8, S8/S8+ and S7/S7 edge.',
    Presentations: [
      {
        PresentationID: '01',
        PresentationTitle:
          'Remote Test Lab: What’s new in TV development environment',
        AbstractTextShort:
          "One UI 4 is the next phase in the evolution of One UI. In this session, we'll see One UI 4 innovations that help you focus on what matters, feel comfortable while using the interface and express yourself and your style — watch for the exploding emoji! And you'll get a look at how we're using design to unify our ecosystem, from phones and foldables to tablets, Galaxy Books and watches.",
        image: `${process.env.PUBLIC_URL}/images/img-session-assets-01.png`,
        color: '#FFCA0F',
        MainCategory: 'Device AI',
        PresentationTag: ['Design', 'IoT'],
        SessionType: 'Mega Session',
        status: 'live',
        sessionLink: 'session1',
      },
      {
        PresenterID: '02',
        PresentationTitle:
          'Creating spectacular Galaxy Game Audio Experiences with Dolby Atmos',
        AbstractTextShort:
          'Usage of foldable devices is rapidly growing. This session will walk through the design process behind foldable devices, their multitasking capabilities as well as Flex mode. Understanding these topics will help developers maximize the foldable experience with their own apps across a growing number of foldable devices. We will also cover how to use the Jetpack WindowManager library, and how to optimize web pages for Flex mode using web standard Device Posture API for unique and differentiated experiences.',
        image: `${process.env.PUBLIC_URL}/images/img-session-assets-02.png`,
        color: '#2088FD',
        PresentationTag: ['Design', 'IoT'],
        MainCategory: 'Device AI',
        SessionType: 'Mega Session',
        status: 'done',
        sessionLink: 'session2',
      },
      {
        PresenterID: '03',
        PresentationTitle:
          'SmartThings Find: Our achievement and partnershipse',
        AbstractTextShort:
          "The new Galaxy Watch Ecosystem is here! Are you curious to learn about the platform we created with Google? By attending this insightful session, you’ll discover why Samsung took the leap away from a legacy OS and joined a new unified platform for our line of Galaxy Watches. This highlight clip will uncover the benefits that can be realized by our developers, designers and end consumers. This informative presentation will guide the participant on how Samsung has simplified app development and watch face creation for our app community, while providing platform familiarity, maximum exposure and app distribution to an extensive user base. Samsung's push for excellence in health and wellness is enhanced by innovative multi-sensors and optimized APIs. Come and learn about the next phase of our Galaxy Watch Ecosystem!",
        image: `${process.env.PUBLIC_URL}/images/img-session-assets-03.png`,
        color: '#FFAE34',
        PresentationTag: ['Design', 'IoT'],
        MainCategory: 'Device AI',
        SessionType: 'Mega Session',
        status: 'prepare status',
        sessionLink: 'session2',
      },
    ],
  },
];
