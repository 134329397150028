import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import {
  Button,
  LoadingCard,
  LoadingCardBottom,
  LoadingCardIcon,
  LoadingCardInner,
  LoadingCardTop,
  MainSession,
  MainSessionCard,
  MainSessionCardInfo,
  MainSessionCardBg,
  MainSessionCardInner,
  MainSessionCardTitle,
  MainSessionCardWrap,
  MainSessionInner,
  MainSessionSubText,
} from './MainSessionElement';
import { MainSessionData } from '../../sample/MainSessionData';
import arrLottie from '../../lottie/plusbtn_white.json';
import MainSectionTitle from '../MainSectionTitle';
import './style.scss';

// Card component
const CardItem = ({ item, idx, setModal, setModalFalse, bookmark }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [shouldUseLottie, setShouldUseLottie] = useState(
    window.innerWidth >= 768
  );

  // Handle window resize event
  useEffect(() => {
    const handleResize = () => {
      setShouldUseLottie(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const renderBg = (num) => {
    let result = '';
    for (let i = 0; i < num; i++) {
      result += '<span></span>';
    }
    return result;
  };

  return (
    <MainSessionCard
      className="MainSessionCard"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <MainSessionCardWrap>
        <Link className="MainSessionLink" to={`/${item.sessionLink}`} />
        <MainSessionCardInner className="CardInner">
          <MainSessionCardBg
            className="CardBg"
            dangerouslySetInnerHTML={{ __html: renderBg(item.bgImgNum) }}
          />
          <MainSessionCardInfo className="Info">
            <MainSessionCardTitle className="Title">
              {item.title.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < item.title.split('\n').length && <br />}
                </React.Fragment>
              ))}
            </MainSessionCardTitle>
            <MainSessionSubText
              className="Text"
              hasW100={idx === 0 || idx === MainSessionData.length}
            >
              {item.description.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < item.description.split('\n').length && <br />}
                </React.Fragment>
              ))}
            </MainSessionSubText>
          </MainSessionCardInfo>
        </MainSessionCardInner>
        <Button
          className={`more-link ${
            idx === 0 || idx === MainSessionData.length ? 'hasW100' : ''
          }`}
        >
          {shouldUseLottie && (
            <Lottie
              key={isHovered ? 'hovered' : 'not-hovered'}
              animationData={arrLottie}
              loop={false}
              autoplay={isHovered}
              onComplete={() => {
                // Lottie 애니메이션이 끝났을 때 호출되는 함수
              }}
            />
          )}
          {!shouldUseLottie && (
            <img
              className="ic-mo"
              src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
              alt="ic"
            />
          )}
        </Button>
      </MainSessionCardWrap>
    </MainSessionCard>
  );
};

// Card loading component
const LoadingItem = () => {
  return (
    <LoadingCard>
      <LoadingCardInner>
        <LoadingCardTop>
          <LoadingCardIcon>
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
          </LoadingCardIcon>
        </LoadingCardTop>
        <LoadingCardBottom>
          <div />
          <div />
          <div />
        </LoadingCardBottom>
      </LoadingCardInner>
    </LoadingCard>
  );
};

const MainSessionContent = () => {
  const isLoading = useSelector((state) => state.sessionList.isLoading);

  // fade effect
  const MainRef = useRef();
  const MainSessionRef = useRef();
  const tl = useRef();
  const [hasRendered, setHasRendered] = useState(false);
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    setHasRendered(true);
  }, []);
  useEffect(() => {
    if (hasRendered) {
      const cardArray = gsap.utils.toArray(MainSessionRef.current.children);
      ScrollTrigger.matchMedia({
        '(min-width: 768px)': function () {
          // cardArray.forEach((el) => {
          //   tl.current = gsap.timeline({
          //     scrollTrigger: {
          //       trigger: el,
          //       start: 'top 90%',
          //       end: '+=500px',
          //       scrub: true,
          //       markers: true,
          //       onUpdate: (self) => {
          //         if (self.progress > 0.1) {
          //           el.classList.add('active');
          //         } else {
          //           el.classList.remove('active');
          //         }
          //       },
          //     },
          //   });
          // });
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: MainSessionRef.current,
              start: 'top 90%',
              end: '+=200',
              scrub: true,
              onUpdate: (self) => {
                if (self.progress > 0.1) {
                  MainSessionRef.current?.classList.add('active');
                } else {
                  MainSessionRef.current?.classList.remove('active');
                }
              },
            },
          });
        },
        '(max-width: 767px)': function () {
          tl.current = gsap
            .timeline({
              scrollTrigger: {
                trigger: MainRef.current,
                start: 'top',
                end: '+=200%',
                scrub: true,
              },
            })
            .fromTo(cardArray[0], { y: 700 }, { y: 0 })
            .fromTo(cardArray[1], { y: 700 }, { y: 0 })
            .fromTo(cardArray[2], { y: 700 }, { y: 0 })
            .fromTo(cardArray[3], { y: 700 }, { y: 0 })
            .fromTo(cardArray[4], { y: 700 }, { y: 0 })
            .fromTo(cardArray[5], { y: 700 }, { y: 0 })
            .to({}, { duration: 0.4 });
        },
      });
    }
    return () => {
      if (tl.current) tl.current.kill();
    };
  });

  return (
    <MainSession ref={MainRef}>
      <div className="sticky">
        <MainSectionTitle
          title="Sessions"
          desc={
            <div>
              Join experts and discover more about the latest developments{' '}
              <br />
              on software, services and platforms.
            </div>
          }
          linkText="Go to Sessions"
          buttonUrl="/sessions"
        />
        <MainSessionInner ref={MainSessionRef}>
          {MainSessionData.length > 0
            ? MainSessionData.map((item, idx) => (
                <CardItem key={idx} item={item} idx={idx} />
              ))
            : isLoading && [...Array(5)].map((d, idx) => <LoadingItem />)}
        </MainSessionInner>
      </div>
    </MainSession>
  );
};

export default MainSessionContent;
