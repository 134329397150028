import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  ActivityContainer,
  ActivityInner,
  TabPanelBox,
  ListBox,
  CardListBox,
  ReservedBox,
  ReservedLink,
  CardMoreBtn,
} from './MypageActivityElements';
import Section from '../Section';
import CardSession from '../CardSession';
import Button from '../Button';
import ToastPopup from '../ToastPopup';
import Chevron from './Chevron';
import CardReserved from '../CardReserved';
import { convertURL } from '../../utils/Functions';
import {
  RESET_RESERVATION_MOTION,
  RESET_BOOKMARK_MOTION,
  RESET_LIKE_MOTION,
} from '../../redux/ActionTypes';
import { colorMapping } from '../../utils/SessionColor';

const MypageActivity = ({ isRegistered, likeData }) => {
  const bookmarkData = useSelector((state) => state.bookmarkList.data);
  const reservationData = useSelector((state) => state.reservationData.data);
  const reservationMotion = useSelector(
    (state) => state.reservationData.motion
  );
  const bookmarkMotion = useSelector((state) => state.bookmarkList.motion);
  const likeMotion = useSelector((state) => state.sessionLikeStatus.motion);
  const phase = useSelector((state) => state.meta.data.phase);
  const dispatch = useDispatch();

  const [count, setCount] = useState(0);
  const [more, setMoreBtn] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalFalse, setModalFalse] = useState(false);

  // My Activity tab
  const [tabIdx, setTab] = useState(0);
  const tabHandler = (index) => {
    setTab(index);
  };

  // useEffect(() => {
  //   const data =
  //     (isRegistered && tabIdx === 0) || (!isRegistered && tabIdx === 1)
  //       ? bookmarkData
  //       : (isRegistered && tabIdx === 1) || (!isRegistered && tabIdx === 2)
  //       ? likeData
  //       : !isRegistered && tabIdx === 0
  //       ? reservationData
  //       : [];

  //   if (data) {
  //     if (data.length < 4) {
  //       setCount(data.length);
  //     } else {
  //       setCount(3);
  //     }
  //   }
  //   setMoreBtn(false);
  // }, [tabIdx]);

  useEffect(() => {
    if (bookmarkData) {
      if (bookmarkData.length < 4) {
        setCount(bookmarkData.length);
      } else {
        setCount(3);
      }
      setMoreBtn(false);
    }
  }, []);

  // toast popup handler
  useEffect(() => {
    if (reservationMotion && reservationMotion === 'delete') {
      setModalFalse(false);
      setModal(true);
      // reset motion value
      dispatch({ type: RESET_RESERVATION_MOTION });
    }

    if (bookmarkMotion && bookmarkMotion === 'delete') {
      setModalFalse(false);
      setModal(true);
      dispatch({ type: RESET_BOOKMARK_MOTION });
    }

    if (likeMotion && likeMotion === 'unlike') {
      setModalFalse(false);
      setModal(true);
      dispatch({ type: RESET_LIKE_MOTION });
    }
  }, [reservationMotion, bookmarkMotion, likeMotion]);

  const onClick = (e, type) => {
    e.preventDefault();
    if (type === 'bookmark') {
      setCount(more ? 3 : bookmarkData.length);
    } else if (type === 'like') {
      setCount(more ? 3 : likeData.length);
    } else if (type === 'reservation') {
      setCount(more ? 3 : reservationData.length);
    }
    setMoreBtn(!more);
  };

  return (
    <ActivityContainer className="activity">
      <ActivityInner>
        {/* phase2] title = Bookmarked (My Activities -> Bookmarked) */}
        <Section title="Bookmarked" className="bookmark" />
        {/* {isRegistered ? (
          <>
            <Tabs
              onSelect={(index) => tabHandler(index)}
              selectedIndex={tabIdx}
            >
              <TabList>
                <Tab>Bookmarked</Tab>
                <Tab>Liked</Tab>
              </TabList>

              <TabPanelBox>
                <TabPanel className="cardSession">
                  {bookmarkData && bookmarkData.length > 0 ? (
                    <>
                      {bookmarkData.map((item, idx) => (
                        <CardSession
                          key={idx}
                          id={item.PresentationID}
                          col={`${idx + 1 > count ? 'none' : 'col-3'} `}
                          title={item.PresentationTitle}
                          date={item.date}
                          description={item.AbstractTextShort?.replace(
                            /\u00a0/gi,
                            ' '
                          )}
                          image={item.PresentationImage}
                          deleteCard
                          tag={
                            item.PresentationTag && item.MainCategory
                              ? [item.MainCategory, ...item.PresentationTag]
                              : item.MainCategory
                              ? [item.MainCategory]
                              : null
                          }
                          type={item.SessionType || item.PresentationType}
                          sessionData={item}
                          setModal={setModal}
                          setModalFalse={setModalFalse}
                          live={item.status === 'live' && 'on'}
                          cardType="bookmark"
                        />
                      ))}
                      {bookmarkData && bookmarkData.length > 6 && (
                        <CardMoreBtn>
                          <Button
                            color="white"
                            size="medium"
                            outline="outline"
                            className="moreBtn"
                            onClick={(e) => onClick(e, 'bookmark')}
                          >
                            {more ? 'Less' : 'More'}
                          </Button>
                        </CardMoreBtn>
                      )}
                    </>
                  ) : (
                    <ReservedBox>
                      <div className="box-inner">
                        <div className="text">
                          You don't have any bookmarked sessions yet.
                        </div>
                        <ReservedLink>
                          <Link to="/sessions">
                            Go to the Session List
                            <Chevron />
                          </Link>
                        </ReservedLink>
                      </div>
                    </ReservedBox>
                  )}
                </TabPanel>
                <TabPanel className="cardSession">
                  {likeData && likeData.length > 0 ? (
                    <>
                      {likeData.map((item, idx) => (
                        <CardSession
                          key={idx}
                          id={item.PresentationID}
                          col={`${idx + 1 > count ? 'none' : 'col-3'} `}
                          // col="col-3"
                          title={item.PresentationTitle}
                          description={item.AbstractTextShort?.replace(
                            /\u00a0/gi,
                            ' '
                          )}
                          image={item.PresentationImage}
                          deleteCard
                          tag={
                            item.PresentationTag && item.MainCategory
                              ? [item.MainCategory, ...item.PresentationTag]
                              : item.MainCategory
                              ? [item.MainCategory]
                              : null
                          }
                          type={item.SessionType || item.PresentationType}
                          sessionData={item}
                          setModal={setModal}
                          setModalFalse={setModalFalse}
                          live={item.status === 'live' && 'on'}
                          cardType="like"
                        />
                      ))}
                      {likeData && likeData.length > 6 && (
                        <CardMoreBtn>
                          <Button
                            color="white"
                            size="medium"
                            outline="outline"
                            className="moreBtn"
                            onClick={(e) => onClick(e, 'like')}
                          >
                            {more ? 'Less' : 'More'}
                          </Button>
                        </CardMoreBtn>
                      )}
                    </>
                  ) : (
                    <ReservedBox>
                      <div className="box-inner">
                        <div className="text">
                          You don't have any liked sessions yet
                        </div>
                        <ReservedLink>
                          <Link to="/sessions">
                            Go to the Session List
                            <Chevron />
                          </Link>
                        </ReservedLink>
                      </div>
                    </ReservedBox>
                  )}
                </TabPanel>
              </TabPanelBox>
            </Tabs>
          </>
        ) : (
          <>
            <Tabs
              onSelect={(index) => tabHandler(index)}
              selectedIndex={tabIdx}
            >
              <TabList>
                <Tab>Reserved</Tab>
                <Tab>Bookmarked</Tab>
                <Tab>Liked</Tab>
              </TabList>

              <TabPanelBox>
                <TabPanel>
                  {reservationData && reservationData.length > 0 ? (
                    <ListBox>
                      {reservationData.map((item, idx) => (
                        <CardReserved
                          key={idx}
                          id={item.id}
                          col={`${idx + 1 > count ? 'none' : 'col-3'} `}
                          title={item.PresentationTitle}
                          date={`${moment(
                            item.PresentationDateTimeStart
                          ).format('HH:mm')} - ${moment(
                            item.PresentationDateTimeEnd
                          ).format('HH:mm')}`}
                          description={item.AbstractTextShort}
                          image={item.PresentationImage}
                          deleteCard
                          tag={item.PresentationTag}
                          type={item.SessionType}
                          setModal={setModal}
                          setModalFalse={setModalFalse}
                          url={`/sessions/${convertURL(
                            item.PresentationTitle
                          )}`}
                          sessionId={item.PresentationID}
                        />
                      ))}
                      {reservationData && reservationData.length > 6 && (
                        <Button
                          color="white"
                          size="medium"
                          outline="outline"
                          className="moreBtn"
                          onClick={(e) => onClick(e, 'reservation')}
                        >
                          {more ? 'Less' : 'More'}
                        </Button>
                      )}
                    </ListBox>
                  ) : (
                    <ReservedBox>
                      <div className="box-inner">
                        <div className="text">
                          You don't have any reserved sessions yet.
                        </div>
                        <ReservedLink>
                          <Link to="/sessions">
                            Go to the Session List
                            <Chevron />
                          </Link>
                        </ReservedLink>
                      </div>
                    </ReservedBox>
                  )}
                </TabPanel>
                <TabPanel className="cardSession">
                  {bookmarkData && bookmarkData.length > 0 ? (
                    <>
                      {bookmarkData.map((item, idx) => (
                        <CardSession
                          key={idx}
                          id={item.PresentationID}
                          col={`${idx + 1 > count ? 'none' : 'col-3'} `}
                          title={item.PresentationTitle}
                          description={item.AbstractTextShort?.replace(
                            /\u00a0/gi,
                            ' '
                          )}
                          image={item.PresentationImage}
                          deleteCard
                          tag={
                            item.PresentationTag && item.MainCategory
                              ? [item.MainCategory, ...item.PresentationTag]
                              : item.MainCategory
                              ? [item.MainCategory]
                              : null
                          }
                          type={item.SessionType || item.PresentationType}
                          sessionData={item}
                          setModal={setModal}
                          setModalFalse={setModalFalse}
                          live={item.status === 'live' && 'on'}
                          cardType="bookmark"
                        />
                      ))}
                      {bookmarkData && bookmarkData.length > 6 && (
                        <CardMoreBtn>
                          <Button
                            color="white"
                            size="medium"
                            outline="outline"
                            className="moreBtn"
                            onClick={(e) => onClick(e, 'bookmark')}
                          >
                            {more ? 'Less' : 'More'}
                          </Button>
                        </CardMoreBtn>
                      )}
                    </>
                  ) : (
                    <ReservedBox>
                      <div className="box-inner">
                        <div className="text">
                          You don't have any bookmarked sessions yet.
                        </div>
                        <ReservedLink>
                          <Link to="/sessions">
                            Go to the Session List
                            <Chevron />
                          </Link>
                        </ReservedLink>
                      </div>
                    </ReservedBox>
                  )}
                </TabPanel>
                <TabPanel className="cardSession">
                  {likeData && likeData.length > 0 ? (
                    <>
                      {likeData.map((item, idx) => (
                        <CardSession
                          key={idx}
                          id={item.PresentationID}
                          col={`${idx + 1 > count ? 'none' : 'col-3'} `}
                          // col="col-3"
                          title={item.PresentationTitle}
                          description={item.AbstractTextShort?.replace(
                            /\u00a0/gi,
                            ' '
                          )}
                          image={item.PresentationImage}
                          deleteCard
                          tag={
                            item.PresentationTag && item.MainCategory
                              ? [item.MainCategory, ...item.PresentationTag]
                              : item.MainCategory
                              ? [item.MainCategory]
                              : null
                          }
                          type={item.SessionType || item.PresentationType}
                          sessionData={item}
                          setModal={setModal}
                          setModalFalse={setModalFalse}
                          live={item.status === 'live' && 'on'}
                          cardType="like"
                        />
                      ))}
                      {likeData && likeData.length > 6 && (
                        <CardMoreBtn>
                          <Button
                            color="white"
                            size="medium"
                            outline="outline"
                            className="moreBtn"
                            onClick={(e) => onClick(e, 'like')}
                          >
                            {more ? 'Less' : 'More'}
                          </Button>
                        </CardMoreBtn>
                      )}
                    </>
                  ) : (
                    <ReservedBox>
                      <div className="box-inner">
                        <div className="text">
                          You don't have any liked sessions yet.
                        </div>
                        <ReservedLink>
                          <Link to="/sessions">
                            Go to the Session List
                            <Chevron />
                          </Link>
                        </ReservedLink>
                      </div>
                    </ReservedBox>
                  )}
                </TabPanel>
              </TabPanelBox>
            </Tabs>
          </>
        )} */}
        <CardListBox>
          {bookmarkData && bookmarkData.length > 0 ? (
            <>
              {bookmarkData.map((item, idx) => {
                const type =
                  (item.SessionType || item.PresentationType) &&
                  (item.SessionType || item.PresentationType).toLowerCase() ===
                    'keynote'
                    ? 'keynote'
                    : item.MainCategory
                    ? item.MainCategory
                    : '-';
                return (
                  <CardSession
                    key={idx}
                    id={item.PresentationID}
                    col={`${idx + 1 > count ? 'none' : 'col-3'} `}
                    title={item.PresentationTitle}
                    date={item.date}
                    description={item.AbstractTextShort?.replace(
                      /\u00a0/gi,
                      ' '
                    )}
                    image={item.PresentationImage}
                    // deleteCard
                    // tag={
                    //   item.PresentationTag && item.MainCategory
                    //     ? [item.MainCategory, ...item.PresentationTag]
                    //     : item.MainCategory
                    //     ? [item.MainCategory]
                    //     : null
                    // }
                    tag={
                      item.PresentationTag ? [...item.PresentationTag] : null
                    }
                    // type={item.SessionType || item.PresentationType}
                    type={type}
                    color={type !== '-' ? colorMapping[type] : ''}
                    // colorMapping={}
                    sessionData={item}
                    setModal={setModal}
                    setModalFalse={setModalFalse}
                    live={item.status === 'live' && 'on'}
                    cardType="bookmark"
                    bookmark
                  />
                );
              })}
              {bookmarkData && bookmarkData.length > 3 && (
                <CardMoreBtn>
                  <Button
                    color="white"
                    size="medium"
                    outline="outline"
                    className="moreBtn"
                    onClick={(e) => onClick(e, 'bookmark')}
                  >
                    {more ? 'Less' : 'More'}
                  </Button>
                </CardMoreBtn>
              )}
            </>
          ) : (
            <ReservedBox>
              <div className="box-inner">
                <div className="text">
                  You don't have any bookmarked sessions yet. Go to the Session
                  List.
                </div>
                <ReservedLink>
                  <Link to="/sessions">
                    Go to the Session List
                    <Chevron />
                  </Link>
                </ReservedLink>
              </div>
            </ReservedBox>
          )}
        </CardListBox>
      </ActivityInner>

      {/* toast popup */}
      {/* 북마크 설정 팝업 */}
      {/* <ToastPopup
        modal={modal}
        setModal={setModal}
        state="success"
        text="This session is kept as a bookmark."
      /> */}

      {/* 북마크 해제 팝업 */}
      <ToastPopup
        modal={modal}
        setModal={setModal}
        state="success"
        text="Successfully deleted."
      />
    </ActivityContainer>
  );
};

export default MypageActivity;
