import { call, put, takeLatest, select } from 'redux-saga/effects';
import moment from 'moment';
import * as types from '../ActionTypes';
import { Get, Post } from '../../utils/Requester';
import { getBookmarkList } from './UserBookmarkSaga';
// eslint-disable-next-line import/no-cycle
import { notiReservation } from './ReservationSaga';
import { getMetaLive } from './MetaSaga';

export function* getUserRegisterType() {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      return null;
    }
    const { data, error } = yield call(Get, {
      url: '/api/user/register',
    });
    if (error || typeof data === 'string') {
      console.error('getUserRegisterType', error);
      return null;
    }
    return data.registerType;
  } catch (err) {
    console.error('getUserRegisterType', err);
    return null;
  }
}

function* getUserSession() {
  try {
    yield call(getMetaLive); // get meta data

    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      return yield put({
        type: types.UPDATE_USER_SESSION,
        isLogin: true,
        countryCode: 'KOR',
      });
    }

    const { data, error } = yield call(Get, {
      url: `/api/user/session`,
    });

    if (error || typeof data === 'string') {
      console.error('UserSessionSaga', error || data);
      yield put({
        type: types.UPDATE_USER_SESSION_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
      return;
    }

    yield put({
      type: types.UPDATE_USER_SESSION,
      isLogin: !!data.userSession,
      countryCode: data?.userSession?.CountryCode,
      showGDPRPopup: data?.showGDPRPopup,
      showGDPRModal: data?.showGDPRModal,
      isCookiePolicy: data?.isCookiePolicy,
      cookieGDPR: data?.cookieGDPR,
    });

    if (data.userSession) {
      // when do not have bookmark data, api call
      const stateBookmark = yield select((state) => state.bookmarkList.data);
      if (stateBookmark && stateBookmark.length > 0) {
        yield put({ type: types.GET_BOOKMARK_LIST, data: stateBookmark });
      } else {
        const bookmark = yield call(getBookmarkList);
        yield put({ type: types.GET_BOOKMARK_LIST, data: bookmark });
      }

      // when do not have reserve data, api call
      // yield call(notiReservation, {
      //   date: moment.utc().valueOf(),
      // });
    }

    // [P3] when do not have reserve data, api call
    // if (data.userSession) {
    //   const stateReserve = yield select(
    //     (state) => state.reservationList.reserve
    //   );
    //   if (stateReserve && stateReserve.length > 0) {
    //     yield put({ type: types.GET_RESERVATION_LIST, data: stateReserve });
    //   } else {
    //     const reserve = yield call(getReservationList);
    //     yield put({ type: types.GET_RESERVATION_LIST, data: reserve });
    //   }
    // }
  } catch (error) {
    if (error) {
      console.warn('error', error);
    }
  }
}

function* updateCookieGDPR({ accept, manageMent }) {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield put({
        type: types.UPDATE_COOKIES_GDPR,
        cookieGDPR: [],
      });
    }
    let cookiePreference = {};
    if (accept === 'all') {
      cookiePreference = {
        performance: true,
        advertising: true,
        functionality: true,
      };
    } else if (accept === 'without') {
      cookiePreference = {};
    } else {
      if (accept.includes('2')) {
        cookiePreference.performance = true;
      }
      if (accept.includes('3')) {
        cookiePreference.advertising = true;
      }
      if (accept.includes('4')) {
        cookiePreference.functionality = true;
      }
    }

    yield call(Post, {
      url: `/api/cookie/preference`,
      body: cookiePreference,
    });

    yield put({
      type: types.UPDATE_COOKIES_GDPR,
      cookieGDPR: accept,
    });

    if (!manageMent) {
      window.location.reload(); // reload page
    }
  } catch (error) {
    if (error) {
      console.warn('error', error);
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_USER_SESSION_REQUEST, getUserSession);
  yield takeLatest(types.UPDATE_COOKIES_GDPR_REQUEST, updateCookieGDPR);
}
