import styled, { keyframes } from 'styled-components';

export const HeroContainer = styled.div`
  position: relative;
  background-color: #fff;
`;

export const HeroDim = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
`;

export const HeroInner = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1;
  &.is-phase3 {
    position: absolute;
  }
`;

export const HeroVisual = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .title-motion {
    position: absolute;
    left: 50%;
    top: 284px;
    width: 1200px;
    height: 236px;
    transform: translateX(-50%);
    pointer-events: none;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .visual-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: scale(0.78) translateY(460px);

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  @media (max-width: 768px) {
    .title-motion {
      top: 178px;
      width: 400px;
      height: 96px;
    }
    .visual-img {
      transform: scale(0.86) translateY(250px);
    }
  }
`;

export const HeroContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateY(450px);
  @media (max-width: 768px) {
    transform: translateY(300px);
  }
`;

export const HeroIntroText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 80px);
  height: 90%;
  padding: 0 40px;
  text-align: center;
  z-index: 10;

  @media (max-width: 768px) {
    width: calc(100% - 48px);
    padding: 0 24px;
  }
`;

export const HeroIntroTitle = styled.div`
  font-family: 'SamsungSharpSans-bold';
  font-size: 100px;
  line-height: 1.3;
  text-align: center;
  color: #fff;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'clig' off, 'liga' off;

  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 1.21;
  }
`;

export const HeroIntroDesc = styled.div`
  margin-top: 32px;
  transform: translateY(50px);
  opacity: 0;

  @media (max-width: 768px) {
    margin-top: 24px;
  }
`;

export const HeroIntroDescItem = styled.div`
  position: relative;
  font-family: 'samsungsharpsans-bold';
  font-size: 24px;
  line-height: normal;
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;

  & + & {
    margin-top: 12px;
  }
  br {
    display: none;
  }

  @media (max-width: 768px) {
    padding: 0;
    font-size: 16px;
    line-height: 1.43;
    white-space: pre-line;
    & + & {
      margin-top: 12px;
    }
    br {
      display: block;
    }
  }
`;

export const HeroIntroBtnWrap = styled.div`
  margin-top: 60px;
  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;

const circleFade = keyframes`
  0% {
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;
export const HeroIntroBtn = styled.div`
  position: relative;
  display: inline-block;

  .Link,
  .aLink {
    display: inline-flex;
    width: 204px;
    padding: 0;
  }

  .Link + .Link {
    margin-left: 16px;
  }

  .btn-reg {
    position: relative;

    span {
      display: inline-block;
      transition: all 0.3s;
    }
    s {
      display: block;
      position: absolute;
      right: 33px;
      top: 50%;
      margin-top: -6px;
      width: 16px;
      height: 12px;
      background-image: url(../images/main-reg-btn-arrow.svg);
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0;
      transition: all 0.2s;
      transition-delay: 0s;
      transform: translateX(-10px);
    }

    .active & {
      span {
        transform: translateX(-12px);
      }
      s {
        transform: translateX(0);
        opacity: 1;
        transition: all 0.3s;
        transition-delay: 0.2s;
        animation: bounce 1.8s;
        animation-iteration-count: 3;
        animation-delay: 0.5s;
      }
    }

    @keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateX(0);
      }
      40% {
        transform: translateX(-8px);
      }
      60% {
        transform: translateX(-4px);
      }
    }
  }

  @media (max-width: 768px) {
    display: block;

    .Link,
    .aLink {
      display: flex;
    }
    .aLink + .Link {
      margin-left: 0;
      margin-top: 8px;
    }

    .btn-reg {
      s {
        right: 38px;
      }

      .active & {
        span {
          transform: translateX(-10px);
        }
      }
    }
  }
`;

export const HeroLink = styled.div`
  margin-top: 70px;
  @media (max-width: 768px) {
    margin-top: 60px;
  }
`;

export const HeroArrow = styled.div`
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  z-index: 30;
  img {
    display: inlinex-block;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    bottom: 16px;
    width: 40px;
    height: 40px;
  }
`;
