import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Slider from 'react-slick';
import { MainKeynoteArea, CardWrap, CardInner } from './MainSlide';
import './style.scss';
import Card from '../CardSpeaker';
import MainSectionTitle from '../MainSectionTitle';
import { KeynoteData } from '../../sample/KeynoteData';

function SlideNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none' }}
      onClick={onClick}
    />
  );
}

function SlidePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none' }}
      onClick={onClick}
    />
  );
}

const MainSlide = () => {
  // Slide setting
  const settings = {
    dots: false,
    speed: 800,
    nextArrow: <SlideNextArrow />,
    prevArrow: <SlidePrevArrow />,
    centerMode: false,
    infinite: false,
    centerPadding: '112px',
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: true,
    touchThreshold: 1000,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    cssEase: 'ease-in-out',
    responsive: [
      {
        breakpoint: 2100,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 2040,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1420,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          centerPadding: '10%',
        },
      },
    ],
  };

  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const { keynoteData, isLoading } = useSelector((state) => state.sessionList);

  const sliderRef = useRef();
  const tl = useRef();
  const [hasRendered, setHasRendered] = useState(false);
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    setHasRendered(true);
  }, []);
  useEffect(() => {
    let intervalID;
    if (hasRendered) {
      ScrollTrigger.matchMedia({
        '(min-width: 1420px)': function () {
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: sliderRef.current,
              start: '0 70%',
              onEnter: () => {
                sliderRef.current?.classList.add('is-active');
                clearTimeout(intervalID);
                intervalID = setTimeout(() => {
                  sliderRef.current?.classList.add('align-card');
                }, 900);
              },
            },
          });
        },
      });
    }
    return () => {
      if (tl.current) tl.current.kill();
      clearTimeout(intervalID);
    };
  });

  return (
    <>
      <MainKeynoteArea>
        <MainSectionTitle
          title="Keynote"
          desc={
            <div>
              Unveil Samsung’s latest software, services, and platforms that are
              poised to
              <br /> shape the future of customer experiences.
            </div>
          }
          linkText="Go to Keynote"
          buttonUrl="/keynote"
        />
        <CardWrap className="main_slide">
          <CardInner
            className={isDesktop ? 'is-DesktopSlide' : ''}
            ref={sliderRef}
          >
            {isDesktop ? (
              <Slider {...settings}>
                {(
                  keynoteData?.Presenters || [{}, {}, {}, {}, {}, {}, {}, {}]
                ).map((item, idx) => (
                  <div
                    key={idx}
                    className={`slider-item ${idx === 1 ? 'long-text' : ''}`}
                  >
                    <Card
                      key={item.PresenterID}
                      id={item.PresenterID}
                      title={`${item.PresenterFirstName} ${item.PresenterLastName}`}
                      description={`${item.PresenterOrganization}`}
                      personImage={item.PresenterPhotoFileName}
                      hoverTitle={item.PresenterCustomField3}
                      hoverBg={
                        KeynoteData.Presenters.filter(
                          (x) => x.PresenterID === item.PresenterID
                        ).length > 0
                          ? KeynoteData.Presenters.filter(
                              (x) => x.PresenterID === item.PresenterID
                            )[0].hoverBg
                          : KeynoteData.Presenters[0].hoverBg
                      }
                      subject="Keynote"
                      slide
                      hover
                      mainslide
                      blBg={item.blBg}
                      isKeynote
                      loading={isLoading}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <Slider {...settings}>
                {(keynoteData?.Presenters || [{}, {}, {}, {}]).map(
                  (item, idx) => (
                    <div
                      key={idx}
                      className={`slider-item ${idx === 1 ? 'long-text' : ''}`}
                    >
                      <Card
                        key={item.PresenterID}
                        id={item.PresenterID}
                        title={`${item.PresenterFirstName} ${item.PresenterLastName}`}
                        description={`${item.PresenterOrganization}`}
                        personImage={item.PresenterPhotoFileName}
                        hoverTitle={item.PresenterCustomField3}
                        hoverBg={
                          KeynoteData.Presenters.filter(
                            (x) => x.PresenterID === item.PresenterID
                          ).length > 0
                            ? KeynoteData.Presenters.filter(
                                (x) => x.PresenterID === item.PresenterID
                              )[0].hoverBg
                            : KeynoteData.Presenters[0].hoverBg
                        }
                        subject="Keynote"
                        slide
                        hover
                        blBg={item.blBg}
                        isKeynote
                        loading={isLoading}
                      />
                    </div>
                  )
                )}
              </Slider>
            )}
          </CardInner>
        </CardWrap>
      </MainKeynoteArea>
    </>
  );
};

export default MainSlide;
